<app-navmenu></app-navmenu>
<app-hero-novedades class="hero-container"></app-hero-novedades>
<div class="page-section">
    <div class="page-section-wrapper">
        <div class="news-search">
            <h2>
            DESTACADO ·
            </h2>
            <!-- <mat-form-field appearance="outline">
                <a href="#" matPrefix class="material-icons">
                    search
                </a>
                <input matInput placeholder="Buscá una nota">
            </mat-form-field> -->
        </div>   
    </div>
</div>
<div class="news-card-destacado-container" *ngFor="let newHighlighted of highlighted">
    <app-news-card-destacado [highlighted]="newHighlighted"></app-news-card-destacado>
</div>
<div class="page-section">
    <div class="page-section-wrapper otras-novedades" *ngIf="notHilhlightedLength>=1">
        <h3>OTRAS <br>· NOVEDADES ·</h3>
        <div class="news-cards-container">
            <div class="news-cards-container-card" *ngFor="let new of notHighlightedShow">
                <app-news-card [new]="new"></app-news-card>
            </div>
        </div>
    </div>
</div>
<div class="page-section">
    <div class="page-section-wrapper">
        <a class="load-more" (click)="showMore()" *ngIf="notHilhlightedLength>=4">Cargar más notas</a>
    </div>
</div>
<!-- <app-suscribite></app-suscribite> -->

<app-accordion-footer></app-accordion-footer>