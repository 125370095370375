import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-elementos-card',
  templateUrl: './elementos-card.component.html',
  styleUrls: ['./elementos-card.component.scss']
})
export class ElementosCardComponent implements OnInit {
  @Input('elementosObject')
  elementosObject:any;

  // @Input('elementosObject.imgUrl1')
  // imgUrl1: string;

  // @Input('elementosObject.imgUrl2')
  // imgUrl2: string = this.elementosObject.imgUrl2;

  constructor() { }

  ngOnInit(): void {
  }

}
