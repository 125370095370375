<section class="comments">
    <div class="comments-wrapper">
        <div class="comment">
            <div class="comment-first-row">
                <p class="comments-name">{{ comment.name }}</p>
                <p class="comments-job">{{ comment.job }}</p>
            </div>
            <div class="comment-second-row">
                <p class="comments-content">{{ comment.content }}</p>
            </div>
            
        </div>
        <div class="response" *ngIf="comment.response">
            <div class="response-first-row">
                <p class="comments-name">Hugo Savarecio</p>
                <p class="comments-job">Dosimetrólogo</p>
            </div>
            <div class="response-second-row">
                <p class="comments-content">{{ comment.response }}</p>
            </div>
        </div>
    </div>
</section>