<header class=hero>
    <div class="hero-content">
        <div class="logo">
            <p></p>
        </div>
        <div class="hero-text"> 
            <h1> <span class="bold"> Asociación</span> civil <br class="hero-text-br-mobile">
            de profesionales <br class="hero-text-br-mobile">
            en <br class="hero-text-br-desktop"> <span class="bold">Tecnologías <br class="hero-text-br-mobile">
            Radiológicas <br class="hero-text-br-mobile">
            Rosario</span> </h1> 
        </div>  
    </div>
</header>

