<app-navmenu></app-navmenu>
<div class="hero-container">
    <h2 class="hero-title">¿En qué podemos ayudarte?</h2>
    <mat-form-field appearance="outline">
      <a href="#" matPrefix class="material-icons">
        search
      </a>
      <input matInput placeholder="Escribe palabras de búsqueda">
    </mat-form-field>
    <div class="hero-description">
        También puedes revisar los siguientes tema para encontrar la información que buscas.
    </div>
</div>
<div class="page-section">
    <div class="page-section-wrapper">
        <h2 class="faq-title">PREGUNTAS FRECUENTES ·</h2>
        <h2>Tema compras</h2>
    </div>
</div>
<mat-accordion>
    <mat-expansion-panel class="expansion-faq">
      <mat-expansion-panel-header class="menu-header">
        <mat-panel-title>
            <p class="title">Esta es una pregunta re larga que llega hasta como por acá?</p> 
        </mat-panel-title>
        <mat-panel-description>
          <!-- {{panelOpenState ? 'open' : 'closed'}} -->
        </mat-panel-description>
      </mat-expansion-panel-header>
        <p class="sub">
            Generalmente, Varsi es mencionado exclusivamente en la polémica 
            relativa a quién obtuvo la primera radiografía en la Argentina; 
            sin embargo, en radiología su aporte más relevante fue la decisión 
            de utilizar el método radiográfico con fines diagnósticos.
            </p>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion>
    <mat-expansion-panel class="expansion-faq">
      <mat-expansion-panel-header class="menu-header">
        <mat-panel-title>
            <p class="title">Esta es una pregunta re larga que llega hasta como por acá?</p> 
        </mat-panel-title>
        <mat-panel-description>
          <!-- {{panelOpenState ? 'open' : 'closed'}} -->
        </mat-panel-description>
      </mat-expansion-panel-header>
        <p class="sub">
            Generalmente, Varsi es mencionado exclusivamente en la polémica 
            relativa a quién obtuvo la primera radiografía en la Argentina; 
            sin embargo, en radiología su aporte más relevante fue la decisión 
            de utilizar el método radiográfico con fines diagnósticos.
            </p>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion>
    <mat-expansion-panel class="expansion-faq">
      <mat-expansion-panel-header class="menu-header">
        <mat-panel-title>
            <p class="title">Esta es una pregunta re larga que llega hasta como por acá?</p> 
        </mat-panel-title>
        <mat-panel-description>
          <!-- {{panelOpenState ? 'open' : 'closed'}} -->
        </mat-panel-description>
      </mat-expansion-panel-header>
        <p class="sub">
            Generalmente, Varsi es mencionado exclusivamente en la polémica 
            relativa a quién obtuvo la primera radiografía en la Argentina; 
            sin embargo, en radiología su aporte más relevante fue la decisión 
            de utilizar el método radiográfico con fines diagnósticos.
            </p>
    </mat-expansion-panel>
</mat-accordion>

<div class="page-section">
    <div class="page-section-wrapper">
        <h2>Tema cursos</h2>
    </div>
</div>
<mat-accordion>
    <mat-expansion-panel class="expansion-faq">
      <mat-expansion-panel-header class="menu-header">
        <mat-panel-title>
            <p class="title">Esta es una pregunta re larga que llega hasta como por acá?</p> 
        </mat-panel-title>
        <mat-panel-description>
          <!-- {{panelOpenState ? 'open' : 'closed'}} -->
        </mat-panel-description>
      </mat-expansion-panel-header>
        <p class="sub">
            Generalmente, Varsi es mencionado exclusivamente en la polémica 
            relativa a quién obtuvo la primera radiografía en la Argentina; 
            sin embargo, en radiología su aporte más relevante fue la decisión 
            de utilizar el método radiográfico con fines diagnósticos.
            </p>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion>
    <mat-expansion-panel class="expansion-faq">
      <mat-expansion-panel-header class="menu-header">
        <mat-panel-title>
            <p class="title">Esta es una pregunta re larga que llega hasta como por acá?</p> 
        </mat-panel-title>
        <mat-panel-description>
          <!-- {{panelOpenState ? 'open' : 'closed'}} -->
        </mat-panel-description>
      </mat-expansion-panel-header>
        <p class="sub">
            Generalmente, Varsi es mencionado exclusivamente en la polémica 
            relativa a quién obtuvo la primera radiografía en la Argentina; 
            sin embargo, en radiología su aporte más relevante fue la decisión 
            de utilizar el método radiográfico con fines diagnósticos.
            </p>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion>
    <mat-expansion-panel class="expansion-faq">
      <mat-expansion-panel-header class="menu-header">
        <mat-panel-title>
            <p class="title">Esta es una pregunta re larga que llega hasta como por acá?</p> 
        </mat-panel-title>
        <mat-panel-description>
          <!-- {{panelOpenState ? 'open' : 'closed'}} -->
        </mat-panel-description>
      </mat-expansion-panel-header>
        <p class="sub">
            Generalmente, Varsi es mencionado exclusivamente en la polémica 
            relativa a quién obtuvo la primera radiografía en la Argentina; 
            sin embargo, en radiología su aporte más relevante fue la decisión 
            de utilizar el método radiográfico con fines diagnósticos.
            </p>
    </mat-expansion-panel>
</mat-accordion>
<div class="fixed-searcher" [ngClass]="{'expand': isSearchExpanded === true }">
  <div class="fixed-searcher-button" 
  (click)="toggleSearcher()">
    <span class="material-icons fixed-searcher-button-icon" 
    [ngClass]="{'show': isSearchExpanded === false }" >
      add
    </span>
    <span class="material-icons fixed-searcher-button-icon" 
    [ngClass]="{'show': isSearchExpanded === true }" >
      remove
    </span>
  </div>
  <mat-form-field appearance="outline">
    <a href="#" matPrefix class="material-icons">
      search
    </a>
    <input matInput placeholder="Buscar">
  </mat-form-field>
</div>

<app-accordion-footer></app-accordion-footer>