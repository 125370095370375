import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.scss']
})
export class NavmenuComponent implements OnInit {

  displayMainMenu: boolean = false;
  displayNosotrosMenu: boolean = false;
  displayDosimetriaMenu: boolean = false;
  activateClickedNosotrosMenu: boolean = false;
  activateClickedDosimetriaMenu: boolean = false;
  activateNosotrosMenu: boolean = false;
  activateDosimetriaMenu: boolean = false;
  isSubMenuOnHover: boolean = false;
  linksNosotros: Array<any>=[
    {
      url: "/nosotros", text: "Asociación"
    },
    {
      url: "/nosotros/historia", text: "Historia"
    },
    {
      url: "/nosotros/museo", text:  "Museo"
    },
    {
      url: "/nosotros/nuestros-profesionales", text: "Nuestros Profesionales"
    },
  ];
  linksDosimetria: Array<any>=[
    {
      url: "/dosimetria", text: "Servicios"
    },
    {
      url: "/dosimetria/elementos", text: "Elementos de Protección"
    },
  ];

  constructor(private router: Router) {
  }

  toggleMainMenu() {
    this.displayMainMenu= !this.displayMainMenu;
  }

  toggleSubMenu(event, section: string): void {
    event.preventDefault();
    if (section=="nosotros") {
      this.displayNosotrosMenu= !this.displayNosotrosMenu;
    }
    if (section=="dosimetria") {
      this.displayDosimetriaMenu= !this.displayDosimetriaMenu;
    }
  }

  toggleClickedActiveSubMenu(event, section: string): void {
    event.preventDefault();
    if (section=="nosotros") {
      this.activateClickedNosotrosMenu= !this.activateClickedNosotrosMenu;
    }
    if (section=="dosimetria") {
      this.activateClickedDosimetriaMenu= !this.activateClickedDosimetriaMenu;
    }
  }

  toggleActiveSubMenu (section: string, activate: boolean, source: string = null): void {
    if (section=="nosotros") {
      if (!this.activateClickedNosotrosMenu) {
      if (activate==false) {
        if (source=="submenu") {
          this.isSubMenuOnHover=false;
        }
        setTimeout(()=>{
          if (!this.isSubMenuOnHover) {
            this.activateNosotrosMenu=false;
          }  
        }, 400)
      }
      else {
        this.activateDosimetriaMenu=false;
        if (source=="submenu") {
          this.isSubMenuOnHover=true;
        }
        this.activateNosotrosMenu= activate;
      } 
    }
  }
    if (section=="dosimetria") {
      if (!this.activateClickedDosimetriaMenu) {
      if (activate==false) {
        if (source=="submenu") {
          this.isSubMenuOnHover=false;
        }
        setTimeout(()=>{
          if (!this.isSubMenuOnHover) {
            this.activateDosimetriaMenu=false;
          }  
        }, 400)
      }
      else {
        this.activateNosotrosMenu=false;
        if (source=="submenu") {
          this.isSubMenuOnHover=true;
        }
        this.activateDosimetriaMenu= activate;
      } 
    }
  }
  }
    

  ngOnInit(): void {
  }

}
