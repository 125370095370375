

<footer class="footer">

<!-- FOOTER LINKS MOBILE -->

  <mat-accordion>
      <mat-expansion-panel class="menu">
        <mat-expansion-panel-header class="menu-header">
          <mat-panel-title class="title">
            CURSOS ATRR
          </mat-panel-title>
          <mat-panel-description>
            <!-- {{panelOpenState ? 'open' : 'closed'}} -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="sub">
          <li><a routerLink="/capacitate">CAPACITATE ATRR</a></li>
          <!-- <li><a routerLink="/capacitate">PLATAFORMA</a></li> -->
          <!-- <li><a routerLink="/faq">FAQ</a></li> -->
        </ul>
      </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
      <mat-expansion-panel class="menu">
        <mat-expansion-panel-header class="menu-header">
          <mat-panel-title class="title">
            DOSIMETRÍA
          </mat-panel-title>
          <mat-panel-description>
            <!-- {{panelOpenState ? 'open' : 'closed'}} -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="sub">
          <li><a routerLink="/dosimetria">INFORMACIÓN DEL SERVICIO</a></li>
        </ul>
      </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
      <mat-expansion-panel class="menu">
        <mat-expansion-panel-header class="menu-header">
          <mat-panel-title class="title">
            NOSOTROS
          </mat-panel-title>
          <mat-panel-description>
            <!-- {{panelOpenState ? 'open' : 'closed'}} -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="sub">
          <li><a routerLink="/nosotros">ASOCIACIÓN</a></li>
          <li><a routerLink="/nosotros/historia">HISTORIA</a></li>
          <li><a routerLink="/novedades">NOVEDADES</a></li>
          <li><a routerLink="/socios">SOCIOS</a></li>
        </ul>
      </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
      <mat-expansion-panel class="menu">
        <mat-expansion-panel-header class="menu-header">
          <mat-panel-title class="title">
            CONTACTO
          </mat-panel-title>
          <mat-panel-description>
            <!-- {{panelOpenState ? 'open' : 'closed'}} -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="sub">
          <li><a routerLink="/contacto">ESCRIBINOS</a></li>
        </ul>
      </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
      <mat-expansion-panel class="menu">
        <mat-expansion-panel-header class="menu-header">
          <mat-panel-title class="title">
            SEGUINOS
          </mat-panel-title>
          <mat-panel-description>
            <!-- {{panelOpenState ? 'open' : 'closed'}} -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="sub">
          <li><a href="https://www.facebook.com/RadiologosRosario/" target="_blank">FACEBOOK</a></li>
          <li><a href="https://www.instagram.com/tecnicos_radiologos_rosario/" target="_blank">INSTAGRAM</a></li>
          <!-- <li><a href="">LINKEDIN</a></li> -->
        </ul>
      </mat-expansion-panel>
  </mat-accordion>

  <!-- <mat-accordion>
      <mat-expansion-panel class="menu">
        <mat-expansion-panel-header class="menu-header">
          <mat-panel-title class="title">
            WHATSAPP
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="sub">
          <li><a href="">
            <img src="../../../assets/footer/wap-logo.svg" alt="">
          </a></li>
        </ul>
      </mat-expansion-panel>
  </mat-accordion> -->



<!-- FOOTER LINKS DESKTOP  -->

<div class="footer-desktop">
  <div class="footer-desktop-container">
    <div class="footer-desktop-item">
      <h5 class="footer-desktop-title">CURSOS ATRR</h5>
      <ul>
        <li><a routerLink="/capacitate">CAPACITATE ATRR</a></li>
        <!-- <li><a routerLink="/capacitate/plataforma">PLATAFORMA</a></li> -->
        <!-- <li><a routerLink="/faq">FAQ</a></li> -->
      </ul>
    </div>
  
    <div class="footer-desktop-item">
      <h5 class="footer-desktop-title">NOSOTROS</h5>
      <ul>
        <li><a routerLink="/nosotros">ASOCIACIÓN</a></li>
        <li><a routerLink="/nosotros/historia">HISTORIA</a></li>
        <li><a routerLink="/novedades">NOVEDADES</a></li>
        <li><a routerLink="/socios">SOCIOS</a></li>
      </ul>
    </div>
  
    <div class="footer-desktop-item">
      <h5 class="footer-desktop-title">DOSIMETRÍA</h5>
      <ul>
        <li><a routerLink="/dosimetria">INFORMACIÓN <br> DEL SERVICIO</a></li>
      </ul>
    </div>
  
  
    <div class="footer-desktop-item">
      <h5 class="footer-desktop-title">SEGUINOS</h5>
      <ul>
        <li><a href="https://www.facebook.com/RadiologosRosario/" target="_blank">FACEBOOK</a></li>
        <li><a href="https://www.instagram.com/tecnicos_radiologos_rosario/" target="_blank">INSTAGRAM</a></li>
        <!-- <li><a href="">LINKEDIN</a></li> -->
      </ul>
    </div>
  
    <div class="footer-desktop-item-final">
      <h5 class="footer-desktop-title">CONTACTO</h5>
      <ul>
        <li><a routerLink="/contacto">ESCRIBINOS</a></li>
      </ul>
    </div>
  
    <!-- <div class="wap-container">
      <h5 class="footer-desktop-title">WHATSAPP</h5>
      <ul>
        <li>
          <a href="">
            <img src="../../../assets/footer/wap-logo.svg" alt="">
          </a>
        </li>
      </ul>
    </div> -->
  </div>
  
</div>
  
  
  
  
<div class="footer-final">
  <div class="logo">
    <p></p>
  </div>
  <div class="description">
    <p><strong>ASOCIACIÓN</strong>  CIVIL DE PROFESIONALES EN 
      <strong>TECNOLOGÍAS RADIOLÓGICAS ROSARIO</strong> </p>
  </div>
</div>
<div class="footer-final-direction">
  <div class="spacer">
    <span></span>
  </div>
  <div class=footer-final-direction-description>
    <div><strong>SEDE:</strong> Italia 47, Rosario</div>
    <div class="footer-final-direction-description-spacer"> · </div>
    <div><strong>TEL:</strong> +54 341 4261377</div>
  </div>
</div>
<div class="footer-rights">
<div>©2020 ATRR. Todos los derechos <br> reservados privacidad</div> 
  
</div>


  

</footer>
