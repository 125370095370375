<div class="card">
    <div class="card-img-wrapper">
        <img src="{{ museumobject.imgUrl }}" alt="">
    </div>
    
    <div class="card-content">
        <div class="card-content-text"> 
            <!-- <h2>{{ museumobject.name }}</h2> -->
               <!-- <p>Fabricante: {{ museumobject.maker }} <br> Año: {{ museumobject.year }}</p>  -->
               <p class="card-content-text-1{{ museumobject.class }}" > <strong> {{ museumobject.text }}</strong> </p>
               <p class="card-content-text-2{{ museumobject.class }}"> {{ museumobject.year }} </p>
        </div>
    </div>
</div>
