<div class="swiper-swiper"><div class="swiper-dosimetria-container">
    <swiper [config]="config" style="height: 430px; width: 100%;" #myswiper>
    <div class="swiper-wrapper">
      <div class="swiper-slide"> <app-card-dosimetria [dosimetroObject]="filmObject"></app-card-dosimetria> </div>
      <div class="swiper-slide"><app-card-dosimetria [dosimetroObject]="tldObject"></app-card-dosimetria></div>
    </div>
    
    <!-- Add Pagination -->
    <!-- <div class="swiper-pagination"></div> -->
    <!-- Add Arrows -->
    <!-- <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
  </swiper>
  </div></div>

  
  <div class="cards-container">
    <div class="cards-container-desktop">
      <div class="cards-container-desktop-card">
        <app-card-dosimetria [dosimetroObject]="filmObject"></app-card-dosimetria>
      </div>
      <div class="cards-container-desktop-card">
        <app-card-dosimetria [dosimetroObject]="tldObject"></app-card-dosimetria>
      </div>
    </div>
  </div>