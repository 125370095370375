<div class="swiper-swiper">
    <div class="swiper-user-get-container">
    <swiper [config]="configUserGet" style="height: 275px; width: 100%;" #myswiper>
        <div class="swiper-wrapper">
            <div class="swiper-slide"> 
                <app-user-swiper-get-card></app-user-swiper-get-card> 
            </div>
            <div class="swiper-slide"> 
                <app-user-swiper-get-card></app-user-swiper-get-card> 
            </div>
        </div>
        
        <!-- Add Pagination -->
        <!-- <div class="swiper-pagination"></div> -->
        <!-- Add Arrows -->
        <!-- <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div> -->
    </swiper>
  </div>
</div>