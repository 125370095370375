<app-navmenu></app-navmenu>
<app-hero class="hero-container"></app-hero>
<div class="shadow-presentation">
    <p></p>
</div>
<div class=presentation>
    <p>
        Somos una <span class=p-bold>asociación civil sin fines de lucro con casi 40 años de trayectoría. </span> 
        Nacida con la ambiciosa idea de reunir a todos los Técnicos que se desempeñan en la Salud de nuestra provincia.
    </p>   
    <p class=alt-color-text>
        Asociación civil de profesionales en Tecnologías Radiológicas Rosario.
   </p>
     
</div>

<app-accordion-dosimetria [homeAccordion]="homeAccordionDosimetria"></app-accordion-dosimetria>
<app-accordion-dosimetria [homeAccordion]="homeAccordionCapacitate"></app-accordion-dosimetria>
<app-accordion-dosimetria [homeAccordion]="homeAccordionProfesionales"></app-accordion-dosimetria>

<div class="asociate">
    <div class="content-wrapper">
        <div class="asoc-description">
        <p>DISFRUTÁ DE LOS BENEFICIOS DE SER PARTE DE ATRR </p>
        </div>
        <div class="second-row">
            <p>Sos de Rosario?</p> <span class=spacer></span>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSezh5BA_TImBrHHcKeN_yfakcAF5uTbAwgIpIHXXxUNHRxZXw/viewform?vc=0&c=0&w=1&flr=0" target="_blank">
                <button mat-flat-button class=asociate-button>
                    formá parte de ATRR
                </button>
            </a>
        </div>
    </div>    
</div>

<div class="shadow-asociate"></div>

<section class="news">
    <div class="news-title">
        <h1>· NOVEDADES ·</h1>
    </div>
    <app-carousel [news]="news"></app-carousel>
    <div class="news-link">
        <a routerLink="/novedades">Ver más novedades<span class="material-icons">
            keyboard_arrow_right_round
        </span></a>
    </div>
</section>
<app-suscribite></app-suscribite>
<app-accordion-footer></app-accordion-footer>


