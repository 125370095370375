<nav>
  <mat-toolbar color="primary" class="toolbar">
    <button mat-icon-button (click)="toggleMainMenu()">
      <mat-icon class=burger-menu>menu</mat-icon>
    </button>
    
    <span class="example-spacer"></span>
    <a routerLink="/" class="logo">ATRR</a> 
    <span class="example-spacer"></span>
    <button routerLink="/login/user-home" mat-icon-button class="toolbar-login">
    <span class="material-icons">
      perm_identity
    </span>
    </button>
  </mat-toolbar>

  <div class="main-menu" [ngClass]="{'main-menu-visible' : displayMainMenu}">
    <ul class="main-menu-items">
      <li class="main-menu-items-home"> 
        <button mat-icon-button routerLink="/">
          <mat-icon class="material-icons-outlined">
            home
          </mat-icon>
        </button>
      </li>
      <li> 
        <a href="#" 
        
        (click)="toggleSubMenu($event, 'nosotros')" 
        (mouseover)="toggleActiveSubMenu('nosotros', true)" 
        (mouseout)="toggleActiveSubMenu('nosotros', false)">
        <!-- (click)="toggleClickedActiveSubMenu($event, 'nosotros')" -->
          Nosotros <span class="material-icons arrow" 
          [ngClass]="{'sub-menu-active' : displayNosotrosMenu}">
            keyboard_arrow_down
            </span>
        </a> 
        <div class="submenus nosotros-section" 
        [ngClass]="{'sub-menu-visible' : displayNosotrosMenu}">
          <app-submenu [links]="linksNosotros"></app-submenu>
        </div> 
      </li>
      <li> 
        <a href="#" 
        (click)="toggleSubMenu($event, 'dosimetria')" 
        (mouseover)="toggleActiveSubMenu('dosimetria', true)" 
        (mouseout)="toggleActiveSubMenu('dosimetria', false)">Dosimetría 
        <span class="material-icons arrow" 
        [ngClass]="{'sub-menu-active' : displayDosimetriaMenu}">
          keyboard_arrow_down
          </span>
        </a> 
        <!-- (click)="toggleClickedActiveSubMenu($event, 'dosimetria')"  -->
        <div class="submenus dosimetria-section" 
        [ngClass]="{'sub-menu-visible' : displayDosimetriaMenu}">
          <app-submenu [links]="linksDosimetria"></app-submenu>
        </div> 
      </li>
      <li><a routerLink="/capacitate">Capacitate ATRR</a></li>
      <li><a routerLink="/novedades">Novedades</a></li>
      <li> <a routerLink="/socios">Socios</a> </li>
      <li> <a routerLink="/contacto">Contacto</a> </li>
      <li class="main-menu-items-login"> 
        <button mat-icon-button routerLink="/login/user-home">
          <mat-icon>perm_identity</mat-icon>
        </button>
      </li>
    </ul>
  </div>
</nav>

<nav class="nav-desktop">
  <div class="submenus-desktop nosotros-section" 
    [ngClass]="{'sub-menu-active' : activateNosotrosMenu}"
    [ngClass]="{'sub-menu-active' : activateClickedNosotrosMenu}">
    <app-submenu 
    [links]="linksNosotros"
    (mouseover)="toggleActiveSubMenu('nosotros', true, 'submenu')" 
    (mouseout)="toggleActiveSubMenu('nosotros', false, 'submenu')"></app-submenu>
  </div> 

  <div class="submenus-desktop dosimetria-section" 
    [ngClass]="{'sub-menu-active' : activateDosimetriaMenu}"
    [ngClass]="{'sub-menu-active' : activateClickedDosimetriaMenu}">
    <app-submenu
    [links]="linksDosimetria"
    (mouseover)="toggleActiveSubMenu('dosimetria', true, 'submenu')" 
    (mouseout)="toggleActiveSubMenu('dosimetria', false, 'submenu')"></app-submenu>
  </div>  
</nav>

