import { InformationRequests } from './../models/InformationRequests';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class InformationRequestsService {

  API_URI = 'https://api.atrr.org.ar/api'

  constructor(private http: HttpClient) { }

  saveForm(informationRequests: InformationRequests) {
    return this.http.post(`${this.API_URI}/information_requests`, informationRequests);
  }
}
