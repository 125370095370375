import { ContactForm } from './../models/ContactForm';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class ContactService {

  //API_URI = 'http://localhost:3000/api'
  API_URI = 'https://api.atrr.org.ar/api'

  constructor(private http: HttpClient) { }

  saveContact(contactForm: ContactForm) {
    return this.http.post(`${this.API_URI}/contact`, contactForm);
  }
}
