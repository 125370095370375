import { AuthService } from './../../services/auth.service';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../services/users.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent implements OnInit {
  userHomeForm: FormGroup;
  userInfo: any = {
    surname: "pepe",
  }

  coursesAcquiredToggle: boolean = false;
  coursesGetToggle: boolean = false;

  constructor(private usersService : UsersService, private router : Router, private activatedRoute: ActivatedRoute, private authService: AuthService) {
    this.buildForm();
   }
  
   private buildForm() {
    this.userHomeForm = new FormGroup({
      name: new FormControl(this.userInfo.name || ''),
      surname: new FormControl(this.userInfo.surname || ''),
      email: new FormControl(this.userInfo.email || ''),
      phone_number: new FormControl(this.userInfo.phone_number || ''),
      country: new FormControl(this.userInfo.country || ''),
      province: new FormControl(this.userInfo.province || ''),
      city: new FormControl(this.userInfo.city || ''),
    });
  }
  
  logOut() {
    this.authService.logOut().subscribe(
      res => {
        console.log(res)
      },
      err => console.error(err)
    );
    document.location.reload();

  }
  ngOnInit(): void {
    const params = JSON.parse(localStorage.userInfo);
    //console.log(params.id)
    this.usersService.getUser(params.id).subscribe(
      res => {
        this.userInfo = res;
        //console.log(this.userInfo)
        this.buildForm()
      },
      err => console.error(err)
    );
  }

  save(event: Event) {

    event.preventDefault();
    if (this.userHomeForm.valid) {
      const params = JSON.parse(localStorage.userInfo);
      console.log(params.id)
      const value = this.userHomeForm.value;
      console.log(value)
      this.usersService.updateUser(params.id, value)
      .subscribe(
        res => {
          console.log(res);
          document.location.reload();
        },
        err => console.log(err)
      )
    } else {
      this.userHomeForm.markAllAsTouched();
      return;
    }
  }

}
