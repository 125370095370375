import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'atrr-fe';

  constructor(private router: Router, private spinner: NgxSpinnerService) { }
    ngOnInit() {
      /** spinner starts on init */
      this.spinner.show();
      setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
      }, 5000);

        //console.log("deploy test");
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
    }
}
