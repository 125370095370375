<app-navmenu></app-navmenu>
<app-hero-dosimetria class=hero-container></app-hero-dosimetria>
<div class="page-section page-section-service">
    <div class="page-section-wrapper">
        <h2>· ¿NECESITAS DOSIMETRÍA? ·</h2>
        <p>Brindamos uno de los mejores servicios del país. 
            Estamos avalados por las instituciones más prestigiosas del sector y 
            por 25 años de experiencia.</p>
    </div>
</div>
<div class="dosimetria-section">
    <div class="dosimetria-section-wrapper">
        <mat-accordion>
            <mat-expansion-panel class="expansion-dosimetria">
              <mat-expansion-panel-header class="menu-header">
                <mat-panel-title class="title">
                  ¿QUÉ ES LA DOSIMETRÍA?
                </mat-panel-title>
                <mat-panel-description>
                  <!-- {{panelOpenState ? 'open' : 'closed'}} -->
                </mat-panel-description>
              </mat-expansion-panel-header>
                <p class="sub">
                    Es la parte de la física que se ocupa de determinar, la cantidad, velocidad y distribución de las radiaciones emitidas por una fuente ionizante. <br>
El dosímetro es el instrumento que mide la dosis absorbida en un contexto de protección radiológica. Sus resultados se utilizan para evaluar y registrar las dosis recibidas por las personas expuestas a radiaciones ionizantes, en función de su trabajo. De este modo se contribuye a preservar la salud de quien lo utiliza. En toda instalación donde se utilicen radiaciones ionizantes es obligatorio el uso de dosímetro en el cumplimiento de la ley número 17.557.
                </p>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<!-- <div class="standard-shadow" id="shadow-desktop"></div> -->

<div class="page-section page-section-service" id="nuestro-servicio">
    <div class="page-section-wrapper">
        
        <h2>· NUESTRO SERVICIO ·</h2>
        <p>En ATRR nos encargamos de brindarte los elementos adecuados para medir tu exposición a 
        las radiaciones. 
        Además nos encargamos de que recibas la información necesaria en tiempo y forma 
        para que siempre sepas las condiciones de seguridad en las que estás trabajando.
        </p>
        <a routerLink="/dosimetria/proceso"><p>Conocé cómo podés adquirir el servicio</p>
            <span class="material-icons">
            keyboard_arrow_right_round
            </span>
        </a>
    </div>
</div>

<div class="page-section">
    <div class="page-section-wrapper">
        <div class="page-section-wrapper-img">
            <picture>
                <source srcset="../../assets/dosimetria/web_dosimetria_img_ilustracion.jpg" media="(min-width: 750px)">
                <img srcset="../../assets/dosimetria/mobile_dosimetria_img_ilustracion.jpg" alt="…">
            </picture>
        </div>
    </div>
</div>

<div class="page-section dosimetria-blue-banner">
    <div class="blue-section-wrapper">
        <h2>COMUNICACIÓN DE ALERTA TEMPRANA</h2>
        <p>Nuestro servicio de Comunicación de Alerta Temprana (CAT) nos permite relevar 
        rápidamente si los valores de exposición están fuera de lo convencional y 
        alertarte a vos y a tus empleadores de la situación. 
        Para nosotros tu salud es primordial y no podemos perder tiempo valioso para que 
        se corrobore el estado de tus elementos de trabajo.</p>
    </div>
</div>
<!-- <div class="standard-shadow"></div> -->
<div class="dosimetria-tecnologias" id="tecnologias">
    <div class="page-section page-section-service">
        <div class="page-section-wrapper">
            <h2 class="header2">· TECNOLOGÍAS QUE UTILIZAMOS ·</h2>
            <p>En ATRR trabajamos con dos tipos de dosímetros: de film y de TLD 
                los más utilizados en el mundo y cada uno de ellos resulta más 
                adecuados para cada práctica que se realice. 
                Tenemos cobertura para todo tipo de actividad: 
                medicina, odontología, veterinaria, ingeniería, metalúrgica, 
                movimiento de suelos, minería, metalmecánica, 
                irradiación de alimentos, seguridad, etc.
            </p>
        </div>  
    </div>
    <app-swiper-dosimetria></app-swiper-dosimetria>
    <div class="page-section page-section-link">
        <div class="page-section-wrapper">
            <a routerLink="/dosimetria/elementos"><p>Te ofrecemos los mejores elementos de protección</p>
            <span class="material-icons">
                keyboard_arrow_right_round
            </span>
            </a>
        </div>  
    </div>
</div>
<div class="standard-shadow"></div>
<div class="download">
    <h3>DESCARGAR FORMULARIOS</h3>
    <div class="download-buttons">
        <a href="../../assets/download/PLANILLA ALTA DOSIMETRIA.xls" download="PLANILLA ALTA DOSIMETRIA.xls"><button mat-flat-button class=download-buttons-button>planilla de alta</button></a>
        <a href="../../assets/download/PLANILLA BAJA DOSIMETRIA.xls" download="PLANILLA BAJA DOSIMETRIA.xls"><button mat-flat-button class=download-buttons-button>planilla de baja</button></a>
        <a href="../../assets/download/CONTRATO DE ACEPTACION DEL SERVICIO.docx" download="CONTRATO DE ACEPTACION DEL SERVICIO.docx"><button mat-flat-button class=download-buttons-button>contrato de aceptación del servicio</button></a>
        <a href="../../assets/download/DEBITO AUTOMATICO DOSIMETRIA.docx" download="DEBITO AUTOMATICO DOSIMETRIA.docx"><button mat-flat-button class=download-buttons-button>débito automático</button></a>
    </div>  
</div>
<!-- AL VOLVER A PONERLO CHECKEAR MARGIN TOP DE .CONTACT -->
<!-- <div>
    <app-swiper-dosimetria-confian></app-swiper-dosimetria-confian>
</div> -->

<div class="standard-shadow" id="shadow-desktop"></div>

<div class="page-section">
    <div class="page-section-wrapper">
        <section class="contact">
            <div class="contact-description">
                <h2>· ADQUIRÍ NUESTRO SERVICIO ·</h2>
                <p>¿Querés adquirir nuestros servicios? <br class="br-desktop"> Comunicarte con nosotros y te responderemos a la brevedad.</p>
            </div>
            <form class="contact-form" novalidate 
            [formGroup]="dosimetriaForm"
            (ngSubmit)="save($event)">
                <div class="contact-form-col1">
                    <div class="form-warn" *ngIf="dosimetriaForm.get('company_name').errors && dosimetriaForm.get('company_name').touched">
                        <small *ngIf="dosimetriaForm.get('company_name').hasError('required')">
                            Este campo es obligatorio.
                        </small>
                    </div> 
                    <mat-form-field appearance="outline" class="">
                        <input matInput placeholder="nombre de la empresa" type="text" formControlName="company_name">
                    </mat-form-field>
        
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="provincia" type="text" formControlName="province">
                    </mat-form-field>
        
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="ciudad" type="text" formControlName="city">
                    </mat-form-field>
                </div>
                    
                <div class="contact-form-col2">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="persona de contacto" type="text" formControlName="contact_name">
                    </mat-form-field>
        
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="teléfono" type="text" formControlName="phone_number">
                    </mat-form-field>
        
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="correo electrónico" type="text" formControlName="email"> 
                    </mat-form-field>
                </div>
                <button mat-flat-button type="submit" class=contact-form-button>enviar</button>
            </form>
        </section>
    </div>
</div>
<div class="standard-shadow"></div>
<div class="nos-avalan">
    <h2>· NOS AVALAN ·</h2>
    <div class="nos-avalan-logos">
        <img src="../../assets/dosimetria/ARN-RED.png" alt="">
        <img src="../../assets/dosimetria/PROV-STA-FE-RED.png" alt="">
        <img src="../../assets/dosimetria/MINISTERIO-DE-SALUD.png" alt="">

    </div>
</div>
<app-accordion-footer></app-accordion-footer>