<app-navmenu></app-navmenu>
<app-hero-dosimetria class=hero-container></app-hero-dosimetria>
<div class="page-section">
    <div class="page-section-wrapper">
        <h2> <span>DOSÍMETROS DE </span> TERMOLUMINISCENCIA</h2>
        <p>
            Formado por cristales que reaccionan generando luz ante 
            la radiación ionzante. La dosis recibida se calcula a partir 
            de la cantidad de luz emitida. El TLD se utiliza en actividades 
            de mayor exposición.
        </p>
        <div class="page-section-wrapper-img">
            <picture>
                <source srcset="../../../assets/dosimetria/tld/web_dosimetria_img_tld1.jpg" media="(min-width: 750px)">
                <img srcset="../../../assets/dosimetria/tld/mobile_dosimetria_img_tld1.jpg" alt="…">
            </picture>
        </div>
        <p>
            Se puede utilizar TLD para radiología convencional, ya que este tipo 
            de dosímetros son capaces de cumplir las mismas funciones que los de film.
        </p>
        <div class="page-section-wrapper-img">
            <picture>
                <source srcset="../../../assets/dosimetria/tld/web_dosimetria_img_tld2.jpg" media="(min-width: 750px)">
                <img srcset="../../../assets/dosimetria/tld/mobile_dosimetria_tld2.jpg" alt="…">
            </picture>
        </div>
        <p>
            En intervencionismo donde los grados de exposición son mayores también se 
            utiliza un TLD en un porta dosímetros de anteojos o barbijo. Contamos con 
            lo necesario para proveerte en estas actividades.
        </p>
        <div class="page-section-wrapper-img">
            <picture>
                <source srcset="../../../assets/dosimetria/tld/web_dosimetria_img_tld3.jpg" media="(min-width: 750px)">
                <img srcset="../../../assets/dosimetria/tld/mobile_dosimetria_img_tld3.jpg" alt="…">
            </picture>
        </div>
        <p>
            También contamos con porta dosímetros de brazalete que también se utilizan 
            para casos en donde la exposición mayor se concentra en determinadas partes 
            del cuerpo como las manos, antebrazos y brazos.
        </p>
    </div>
    <div class="button">
        <button routerLink="/dosimetria" fragment="tecnologias" mat-flat-button class=back-button>
            volver
        </button>
    </div>
</div>
<app-accordion-footer></app-accordion-footer>