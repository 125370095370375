import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-profesionales',
  templateUrl: './accordion-profesionales.component.html',
  styleUrls: ['./accordion-profesionales.component.scss']
})
export class AccordionProfesionalesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
