import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-elementos',
  templateUrl: './elementos.component.html',
  styleUrls: ['./elementos.component.scss']
})
export class ElementosComponent implements OnInit {

  delantal1 = {
    name: "DELANTAL PLOMADO",
    description: "Delantal plomado color celeste, con espaldar cruzado de 0,5 mm de espesor.",
    imgUrl1: "../../../../../assets/dosimetria/elementos/DELANTAL CELESTE FRONT.jpg",
    imgUrl2: "../../../../../assets/dosimetria/elementos/DELANTAL CELESTE BACK.jpg",
  }

  delantal2 = {
    name: "DELANTAL PLOMADO",
    description: "Delantal plomado con cubre tiroides de color azul de 0,5 o 1 mm de espesor.",
    imgUrl1: "../../../../../assets/dosimetria/elementos/DELANTAL AZUL FRONT.jpg",
    imgUrl2: "../../../../../assets/dosimetria/elementos/DELANTAL AZUL BACK.jpg",
  }

  delantal3 = {
    name: "DELANTAL PLOMADO",
    description: "Delantal plomado pediátrico con cubre tiroides de 0,25 mm de espesor.",
    imgUrl1: "../../../../../assets/dosimetria/elementos/DELANTAL PEDIATRICO FRONT.jpg",
    imgUrl2: "../../../../../assets/dosimetria/elementos/DELANTAL PEDIATRICO BACK.jpg",
  }
  
  guardaDosimetro = {
    name: "GUARDA DOSÍMETROS",
    description: "Guarda dosímetros plomado de 3 medidas.",
    imgUrl1: "../../../../../assets/dosimetria/elementos/elementos-guarda-dosimetro1.jpg",
    imgUrl2: "../../../../../assets/dosimetria/elementos/elementos-guarda-dosimetro2.jpg",
  }

  tiroides = {
    name: "CUBRE TIROIDES H/M",
    description: "Cubre tiroides para hombre o mujer colores azul y celeste.",
    imgUrl1: "../../../../../assets/dosimetria/elementos/elementos-tiroides1.jpg",
    imgUrl2: "../../../../../assets/dosimetria/elementos/elementos-tiroides2.jpg",
  }

  gonadas = {
    name: "CUBRE GÓNADAS H/M",
    description: "Cubre gónadas para hombre o mujer color azul.",
    imgUrl1: "../../../../../assets/dosimetria/elementos/elementos-gonadas1.jpg",
    imgUrl2: "../../../../../assets/dosimetria/elementos/elementos-gonadas2.jpg",
  }

  constructor() { }

  ngOnInit(): void {
  }

}
