<app-navmenu></app-navmenu>
<app-en-construccion-hero class=hero-container></app-en-construccion-hero>
<div class="page-section">
    <div class="page-section-wrapper">
        <h2><span class="bold">
            CAPACITATE ATRR <span class="only-desktop">-</span> 
        </span> <br class="only-mobile">
             VIDEOS DE FORMACIÓN</h2>
        <p>Estamos desarrollando nuestra plataforma de videos formativos 
            con la idea de aportar un granito de arena al desarrollo de nuestros colegas. 
            <strong>Si sos socio quedate atento a nuestras redes sociales que habrá grandes sorpresas 
            muy pronto.</strong> </p>
            <button mat-flat-button routerLink="" class=construccion-button>ir a home</button>
    </div>
</div>





<app-accordion-footer></app-accordion-footer>