<app-navmenu></app-navmenu>
<app-hero-dosimetria class=hero-container></app-hero-dosimetria>
<div class="page-section">
    <div class="page-section-wrapper">
        <h2>· ¿CÓMO ADQUIRIR EL SERVICIO? ·</h2>
        <p>Imprimí las planillas de <strong>ALTA, aceptación del servicio y forma de pago</strong>, 
            completalas y luego enviánoslas por e-mail (el trámite se completará 
            al llegar los originales físicamente a nuestra institución). 
            La solicitud deberá realizarse antes del día 15 de cada mes, 
            para que reciba el/los dosímetros en los primeros días 
            del mes posterior.
        </p>
        <div class="page-section-wrapper-img">
            <!-- <img src="../../../assets/dosimetria/proceso/mobile_dosimetría_img_proceso_1.jpg" alt=""> -->
            <picture>
                <source srcset="../../../assets/dosimetria/proceso/web_dosimetria_img_proceso1.jpg" media="(min-width: 750px)">
                <img srcset="../../../assets/dosimetria/proceso/mobile_dosimetria_img_proceso_1.jpg" alt="…">
            </picture>
        </div>
        <p>Las planillas deberán estar completas,<strong> con firma y 
            sello del Responsable del Servicio.</strong> Cada dosímetro es personalizado y 
            posee un sistema de seguimiento. Solicitá la Constancia de Alta para 
            presentarla a la autoridad competente.</p>
        <div class="page-section-wrapper-img">
            <picture>
                <source srcset="../../../assets/dosimetria/proceso/web_dosimetria_img_proceso2.jpg" media="(min-width: 750px)">
                <img srcset="../../../assets/dosimetria/proceso/mobile_dosimetria_img_proceso_2.jpg" alt="…">
            </picture>
        </div>
        <p>Cuando el trámite administrativo se haya completado recibirás los porta 
            dosímetro de Film o T.L.D en comodato según corresponda. <strong>Si el servicio 
            fuese interrumpido, deberás reintegrar todo el material o abonarlo en 
            caso de extravío o ruptura.</strong>  Nuestro servicio posee un recambio mensual, 
            siendo éste el más conveniente, por la eficacia y velocidad que permite.
        </p>
        <div class="page-section-wrapper-img">
            <picture>
                <source srcset="../../../assets/dosimetria/proceso/web_dosimetria_img_proceso3.jpg" media="(min-width: 750px)">
                <img srcset="../../../assets/dosimetria/proceso/mobile_dosimetria_img_proceso_3.jpg" alt="…">
            </picture>
        </div>
        <p>
           <strong>Es tu responsabilidad enviar dicho material en tiempo y forma,</strong>  para que 
            el laboratorio pueda procesarlos. En caso de que no recibamos el material, 
            recibirás un recordatorio. Podés enviar los dosímetros a nuestra institución,
             de lunes a viernes de (09 a 17Hs) o por mensajería, vía postal o comisionista (a cargo del usuario). 
             Si recibimos los dosímetros antes del día 14, a finales de ese mismo mes recibirás por correo electrónico el informe correspondiente.
        </p>
    </div>
    <div class="button">
        <button mat-flat-button class=back-button>
            volver
        </button>
    </div>
</div>
<app-accordion-footer></app-accordion-footer>
