import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  isSearchExpanded: boolean = false;

  constructor() { }

  toggleSearcher() {
    this.isSearchExpanded = !this.isSearchExpanded;
  }

  ngOnInit(): void {
  }

}
