import { EnConstruccionComponent } from './common/en-construccion/en-construccion.component';
import { AuthGuardService } from './services/auth-guard.service';
import { RegisterComponent } from './login/register/register.component';
import { UserHomeComponent } from './login/user-home/user-home.component';
import { NoticiaComponent } from './novedades/noticia/noticia.component';
import { FaqComponent } from './faq/faq.component';
import { CapacitateComponent } from './capacitate/capacitate.component';
import { LoginComponent } from './login/login.component';
import { NovedadesComponent } from './novedades/novedades.component';
import { FilmComponent } from './dosimetria/film/film.component';
import { TldComponent } from './dosimetria/tld/tld.component';
import { ElementosComponent } from './dosimetria/elementos/elementos.component';
import { ProcesoComponent } from './dosimetria/proceso/proceso.component';
import { SociosComponent } from './socios/socios.component';
import { DosimetriaComponent } from './dosimetria/dosimetria.component';
import { HistoriaComponent } from './nosotros/historia/historia.component';
import { ContactoComponent } from './contacto/contacto.component';
import { MuseoComponent } from './nosotros/museo/museo.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { HeroComponent } from './home/hero/hero.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NuestrosProfesionalesComponent } from './nosotros/nuestros-profesionales/nuestros-profesionales.component';


const routes: Routes = [
   { path: '', component: HomeComponent},
   { path: 'nosotros', children: [
     { path: '', component: NosotrosComponent },
     { path: 'nuestros-profesionales', component: NuestrosProfesionalesComponent }, 
     { path: 'museo', component: MuseoComponent },
     { path: 'historia', component: HistoriaComponent }
   ]},
   { path:'contacto', component: ContactoComponent },
   { path:'dosimetria', children: [
     { path: '', component: DosimetriaComponent },
     { path: 'proceso', component: ProcesoComponent },
     { path: 'elementos', component: ElementosComponent },
     { path: 'tld', component: TldComponent },
     { path: 'film', component: FilmComponent }
   ]},
   { path: 'socios', component: SociosComponent },
   { path: 'novedades', children: [
     { path: '', component: NovedadesComponent },
     { path: 'noticia/:id', component: NoticiaComponent }
   ]},
   { path: 'login', children: [
      { path: '', component: LoginComponent },
      { path: 'user-home', component: UserHomeComponent,  canActivate : [AuthGuardService]},
      { path: 'registrate', component: RegisterComponent}
   ]}, 
   { path: 'capacitate', component: EnConstruccionComponent },
  //  { path: 'faq', component: FaqComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
