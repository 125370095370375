import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'login-error-component',
  template: `<span class="login-error">
          Datos incorrectos, inténtalo nuevamente.
          </span>`,
  styles: [`
    .login-error{
      color: white;
    }
  `],
})
export class LoginErrorComponent {}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  loginForm: FormGroup;

  constructor(private authService : AuthService, private router : Router, private _snackBar: MatSnackBar) {
    this.buildForm();
   }

  ngOnInit(): void {
  }

  private buildForm() {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      //username: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  openSnackBar() {
    this._snackBar.openFromComponent(LoginErrorComponent, {
      duration: 8 * 1000,
      panelClass: ['green-snackbar'],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  save(event: Event) {

    event.preventDefault();
    if (this.loginForm.valid) {
      const value = this.loginForm.value;
      console.log(value)
      this.authService.validate(JSON.stringify(value))
      .subscribe(data => {
        this.authService.setUserInfo(data);
        this.router.navigate(['/login/user-home']);
        //console.log(data)
      },
        error => {
          console.log(error);
          this.openSnackBar();
        })
    } else {
      this.loginForm.markAllAsTouched();
      return;
    }
  }

}
