import { ExternalRequestsService } from '../../services/external-requests.service';
import { ProfesionalesFormDialogComponent } from './profesionales-form-dialog/profesionales-form-dialog.component';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-nuestros-profesionales',
  templateUrl: './nuestros-profesionales.component.html',
  styleUrls: ['./nuestros-profesionales.component.scss']
})
export class NuestrosProfesionalesComponent implements OnInit {

  profesionalesForm: FormGroup;

  constructor(public dialog: MatDialog, private externalRequestsService: ExternalRequestsService) { 
    this.buildForm();
  }

  openDialog() {
    this.dialog.open(ProfesionalesFormDialogComponent);
  }
  
  ngOnInit(): void {
  }

  private buildForm() {
    this.profesionalesForm = new FormGroup({
      company_name: new FormControl('', [Validators.required]),
      province: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      contact_name: new FormControl('', [Validators.required]),
      phone_number: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email, Validators.required]),
    });
  }

  save(event: Event) {
    event.preventDefault();
    if (this.profesionalesForm.valid) {
      const value = this.profesionalesForm.value;
      value['request_type']="profesionales";
      console.log(value);
      this.externalRequestsService.saveForm(value)
        .subscribe(
          res => {
            console.log(res);
          },
          err => console.log(err)
        )
      this.openDialog();
    } else {
      this.profesionalesForm.markAllAsTouched();
    }
  }
}
