<div class="swiper-swiper"><div class="swiper-socios-container">
    <swiper [config]="config" style="height: 406px; width: 100%;" #myswiper>
    <div class="swiper-wrapper">
      <div class="swiper-slide"> <app-card-museo [museumObject]="coso1"></app-card-museo> </div>
      <div class="swiper-slide"><app-card-museo [museumObject]="coso2"></app-card-museo> </div>
      <div class="swiper-slide"><app-card-museo [museumObject]="coso3"></app-card-museo> </div>
      <div class="swiper-slide"><app-card-museo [museumObject]="coso4"></app-card-museo></div>
      <div class="swiper-slide"><app-card-museo [museumObject]="coso5"></app-card-museo></div>
      <div class="swiper-slide"><app-card-museo [museumObject]="coso6"></app-card-museo></div>
      <div class="swiper-slide"><app-card-museo [museumObject]="coso7"></app-card-museo></div>
      <div class="swiper-slide"><app-card-museo [museumObject]="coso8"></app-card-museo></div>
      <div class="swiper-slide"><app-card-museo [museumObject]="coso9"></app-card-museo></div>
      <div class="swiper-slide"><app-card-museo [museumObject]="coso10"></app-card-museo></div>
      <div class="swiper-slide"><app-card-museo [museumObject]="coso11"></app-card-museo></div>
    </div>
    
    <!-- Add Pagination -->
    <!-- <div class="swiper-pagination"></div> -->
    <!-- Add Arrows -->
    <!-- <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
  </swiper>
  </div></div>

  <div class="page-section">
    <div class="page-section-wrapper cards-container">
      <div class="cards-container-desktop">
          <div class="cards-container-desktop-card1">
            <app-card-museo [museumObject]="coso1"></app-card-museo>
          </div>
          <div class="cards-container-desktop-card">
            <app-card-museo [museumObject]="coso2"></app-card-museo>
          </div>
          <div class="cards-container-desktop-card3">
            <app-card-museo [museumObject]="coso3"></app-card-museo>
          </div>
      </div>
      <div class="cards-container-desktop">
        <div class="cards-container-desktop-card1">
          <app-card-museo [museumObject]="coso4"></app-card-museo>
        </div>
        <div class="cards-container-desktop-card">
          <app-card-museo [museumObject]="coso5"></app-card-museo>
        </div>
        <div class="cards-container-desktop-card3">
          <app-card-museo [museumObject]="coso6"></app-card-museo>
        </div>
      </div>
      <div class="cards-container-desktop">
        <div class="cards-container-desktop-card1">
          <app-card-museo [museumObject]="coso7"></app-card-museo>
        </div>
        <div class="cards-container-desktop-card">
          <app-card-museo [museumObject]="coso8"></app-card-museo>
        </div>
        <div class="cards-container-desktop-card3">
          <app-card-museo [museumObject]="coso9"></app-card-museo>
        </div>
      </div>
      <div class="cards-container-desktop">
        <span></span>
        <div class="cards-container-desktop-card">
          <app-card-museo [museumObject]="coso10"></app-card-museo>
        </div>
        <span></span>
        <div class="cards-container-desktop-card">
          <app-card-museo [museumObject]="coso11"></app-card-museo>
        </div>
        <span></span>
      </div>
    </div>
  </div>
  <!-- <div class="cards-container">
    
  </div> -->