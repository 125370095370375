<app-navmenu></app-navmenu>
<app-hero-dosimetria class=hero-container></app-hero-dosimetria>
<div class="page-section">
    <div class="page-section-wrapper">
        <h2>
            · ELEMENTOS DE PROTECCIÓN ·
        </h2>
        <p>
            Cuando se trabaja con radiación ionizante el uso permanente de todos 
            los elementos de protección es primordial. <br><br> Por eso ofrecemos productos 
            para resguardar tu salud mientras realizas tu actividad diaria: 
            delantales plomados, chalecos, cubre tiroides, cobertores gonadales, 
            lentes plomados, etc.
        </p>
    </div>
</div>
<div class="page-section elementos-blue">
    <div class="page-section-wrapper elementos-blue-section">
        <p>
            Contactate con nosotros para consultarnos 
            detalles de los elementos de seguridad.
        </p>
    </div>
</div>
<div class="page-section">
    <div class="page-section-wrapper">
        <div class="elementos-container">
            <app-elementos-card [elementosObject]="delantal1"></app-elementos-card>
            <app-elementos-card [elementosObject]="delantal2"></app-elementos-card>
        </div>
        <div class="elementos-container">
            <app-elementos-card [elementosObject]="delantal3"></app-elementos-card>
            <app-elementos-card [elementosObject]="guardaDosimetro"></app-elementos-card>
        </div>
        <div class="elementos-container">
            <app-elementos-card [elementosObject]="tiroides"></app-elementos-card>
            <app-elementos-card [elementosObject]="gonadas"></app-elementos-card>
        </div>
    </div>
</div>

<div class="button">
    <button routerLink="/dosimetria" mat-flat-button class=back-button>
        volver
    </button>
</div>

<app-accordion-footer></app-accordion-footer>