<div class="card" *ngFor="let homeAccordion of homeAccordion">

  <section>
    <img src="{{ homeAccordion.mobileImg }}" alt="{{ homeAccordion.imgAlt }}">
      <!-- <img src="../../../assets/home/mobile_home_img_dosimetria.jpg" alt="ATRR dosimetría santa fe rosario radiología"> -->
  </section>

  <mat-accordion color="primary">
    <mat-expansion-panel class=panel>
      <mat-expansion-panel-header class="menu">
        <mat-panel-title class="title">
        {{ homeAccordion.title }}
          <!-- DOSIMETRÍA -->
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="name">
        <h3>{{ homeAccordion.name }}</h3>
        
        <!-- <h3>SERVICIOS </h3>
        <h3>DE DOSIMETRÍA</h3> -->
      </div>
      <p>
        {{ homeAccordion.description }}
          <!-- La salud de nuestros profesionales es nuestra prioridad. 
          Por eso en ATRR nos hemos especializado para garantizar una medicion 
          fiable. -->
      </p>
      <!-- <a routerLink="/dosimetria" class="accordion-link">  -->
      <a routerLink="{{ homeAccordion.router }}" class="accordion-link"> 
        <div class="accordion-link-text">{{ homeAccordion.link }}</div>  
        <i class="accordion-link-square"></i> 
      </a>
  </mat-expansion-panel>
</mat-accordion>
</div>

<div class="card-desktop-container" *ngFor="let homeAccordion of homeAccordion">
  <div class="card-desktop{{ homeAccordion.class }}">
    <div class="card-desktop-image">
      <img src="{{ homeAccordion.desktopImg }}" alt="">
    </div>
    <div class="card-desktop-content">
      <h2> {{ homeAccordion.name }} </h2>
      <p> {{ homeAccordion.description }}</p>
      <a routerLink="{{ homeAccordion.router }}" class="accordion-link{{ homeAccordion.class }}"> 
          <div class="accordion-link-text{{ homeAccordion.class }}">{{ homeAccordion.link }}</div>  
          <i class="accordion-link-square{{ homeAccordion.class }}"></i> 
      </a>
    </div>
    <div class="card-desktop-title{{ homeAccordion.class }}"> <h1>{{ homeAccordion.title }}</h1> </div>
  </div>
</div>
  