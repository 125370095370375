<section>
    <p></p>
</section>

<mat-accordion>
    <mat-expansion-panel class="panel">
      <mat-expansion-panel-header class="menu">
        <mat-panel-title class="title">
          PROFESIONALES
        </mat-panel-title>
        <mat-panel-description>
          <!-- {{panelOpenState ? 'open' : 'closed'}} -->
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="name">
        <h3>NUESTROS</h3>
        <h3>PROFESIONALES</h3>
      </div>
      <p>
        Encontra el profesional que estas buscando en nuestra amplia base de datos.
        </p>
    </mat-expansion-panel>
  </mat-accordion>