<header class=hero>
    <div class="hero-content">
        <div class="material-icons-outlined">
            people_outline
        </div>
        <div class="hero-text"> 
            <h1>SOCIOS</h1><h2>Los que ya somos 
                <span class=h2-desktop> parte de</span> ATRR</h2>
        </div>  
    </div>
</header>
