<header class=hero>
    <div class="hero-content">
        <div class="material-icons-outlined">
            play_circle_outline
        </div>
        <div class="hero-text"> 
            <h1>CAPACITATE CON ATRR</h1><h2>Nuestros cursos</h2>
        </div>  
    </div>
</header>
