
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SuscribiteService {

  API_URI = 'https://api.atrr.org.ar/api'

  constructor(private http: HttpClient) { }

  saveSubscribe(email: string) {
    return this.http.post(`${this.API_URI}/subscribers`, email);
  }
}
