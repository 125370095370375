<app-navmenu></app-navmenu>
<app-hero-nosotros class="hero-container"></app-hero-nosotros>
<div class="description">
    <h2 class="description-title">COMUNIDAD ATRR</h2>
    <p class="description-par">
        La existencia de nuestra institución fue posible gracias a la pasión por la profesión, el trabajo comprometido de
        distintas generaciones a lo largo de 40 años de existencia, trabajando juntos para el reconocimiento social, llevando
        siempre como bandera la #JERARQUIZACIÓN PROFESIONAL, velando por la salud de todos sus integrantes, su
        capacitación y la dignificación laboral. Estamos orgullosos de lo logrado, pero no satisfechos, por eso deseamos
        continuar creciendo y esforzándonos para ser cada día mejores.</p>
</div>
<app-swiper-nosotros></app-swiper-nosotros>
<div class="invitation">
    <div class="invitation-container">
        <h3>¿Querés ser parte?</h3>
        <p>Asociate a ATTR para poder disfrutar de todos los beneficios que tenemos para vos. 
            Empezado con capacitaciones y sorteos hasta en recreación. 
            Entrá a nuestra sección de socios y fíjate a todo lo que podrías acceder. 
            No esperes más, formá parte nuestra comunidad.</p>
    </div>
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSezh5BA_TImBrHHcKeN_yfakcAF5uTbAwgIpIHXXxUNHRxZXw/viewform?vc=0&c=0&w=1&flr=0" target="_blank"><button mat-flat-button class=invitation-button>quiero ser parte</button></a>
</div>
<app-accordion-footer></app-accordion-footer>

