<div class="card">
    <div class="card-img-container">
        <img src="../../../../../assets/capacitate/course-card/rectangulo-74.png" alt="">
    </div>
    <div class="card-type">TÍTULO O NOMBRE DE LA CAPACITACIÓN 1</div>
    <div class="card-content">
        <div class="card-content-text"> 
            <p>PROFESOR: Steven Spilberg</p> 
            <p>Duración: 45 m</p> 
        </div>
        <div class="card-content-see-more"> 
            <!-- <span class=card-content-see-more-spacer></span>  -->
            <a routerLink="" class=card-content-see-more-button>
                <div class="card-content-see-more-button-text">
                   Ir a la capacitación
                </div> 
                <i class="card-content-see-more-button-square"></i>
            </a> 
        </div>
    </div>
</div>