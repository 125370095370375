<app-navmenu></app-navmenu>
<app-hero-login class="hero-container"></app-hero-login>
<div class="page-section">
    <div class="page-section-wrapper">
        <h2>DATOS DE PERFIL</h2>
        <div class="user-data">
            <!-- <div class="user-data-photo">
                <div class="user-data-photo-wrapper">
                    <img src="../../../assets/login/login-random-photo.png" alt="">
                </div> -->
                <!-- <a href="" class="user-data-photo-change">
                    cambiar foto
                </a> -->
            <!-- </div> -->
            <form novalidate 
            [formGroup]="userHomeForm" class="user-data-form">
                <div class="col1">
                    <mat-form-field appearance="outline" class="">
                        <input matInput type="text" placeholder="Nombre" formControlName="name">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="Apellido" formControlName="surname">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="E-mail" formControlName="email">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="Teléfono" formControlName="phone_number">
                    </mat-form-field>
                </div>
                <div class="col2">
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="País" formControlName="country">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="Provincia" formControlName="province">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="Ciudad" formControlName="city">
                    </mat-form-field>
                <!-- <button mat-flat-button class=user-form-button (click)="save($event)">guardar cambios</button> -->
                </div>
                   
                    
                
            </form>

            <div class="buttons">
                <button mat-flat-button class=user-form-button (click)="save($event)">guardar cambios</button>
                <button mat-flat-button class=logout-button (click)="logOut()">cerrar sesión</button>

            </div>
           

        </div>
    </div>
</div>
<div class="courses-aquired-toggle" *ngIf="coursesAcquiredToggle">
    <div class="standard-shadow"></div>
    <div class="page-section">
        <div class="page-section-wrapper">
            <h3>ÚLTIMAS CAPACITACIONES ADQUIRIDAS</h3>
        </div>
    </div>
    <div class="user-swiper-acquired-wrapper">
        <app-user-swiper-acquired></app-user-swiper-acquired>
    </div>
</div>
<div class="courses-get-toggle" *ngIf="coursesGetToggle">
    <div class="standard-shadow"></div>
    <div class="user-swiper-get-wrapper">
        <div class="page-section">
            <div class="page-section-wrapper">
                <h3>ADQURÍ NUESTRAS NUEVAS CAPACITACIONES</h3>
            </div>
        </div>
        <app-user-swiper-get></app-user-swiper-get>
    </div>
</div>

<app-accordion-footer></app-accordion-footer>