import { SuscribiteService } from './services/suscribite.service';
import { NewsService } from './services/news.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavmenuComponent } from './common/navmenu/navmenu.component';
import { HeroComponent } from './home/hero/hero.component';
import { HomeComponent } from './home/home.component';
import { PresentationComponent } from './home/presentation/presentation.component';
import { AccordionDosimetriaComponent } from './home/accordion-dosimetria/accordion-dosimetria.component';
import { AccordionCapacitateComponent } from './home/accordion-capacitate/accordion-capacitate.component';
import { AccordionProfesionalesComponent } from './home/accordion-profesionales/accordion-profesionales.component';
import { AccordionFooterComponent } from './common/accordion-footer/accordion-footer.component';
import { CarouselComponent } from './home/carousel/carousel.component';
import { NewsCardComponent } from './home/carousel/news-card/news-card.component';
import { SectionCardComponent } from './home/section-card/section-card.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { SubmenuComponent } from './common/navmenu/submenu/submenu.component';
import { NuestrosProfesionalesComponent } from './nosotros/nuestros-profesionales/nuestros-profesionales.component';
import { MuseoComponent } from './nosotros/museo/museo.component';
import { ContactoComponent } from './contacto/contacto.component';
import { HeroContactoComponent } from './contacto/hero-contacto/hero-contacto.component';
import { HeroNosotrosComponent } from './nosotros/hero-nosotros/hero-nosotros.component';
import { SwiperNosotrosComponent } from './nosotros/swiper-nosotros/swiper-nosotros.component';
import { CardsNosotrosComponent } from './nosotros/swiper-nosotros/cards-nosotros/cards-nosotros.component';
import { HistoriaComponent } from './nosotros/historia/historia.component';
import { HeroHistoriaComponent } from './nosotros/historia/hero-historia/hero-historia.component';
import { DosimetriaComponent } from './dosimetria/dosimetria.component';
import { HeroDosimetriaComponent } from './dosimetria/hero-dosimetria/hero-dosimetria.component';
import { CardDosimetriaComponent } from './dosimetria/card-dosimetria/card-dosimetria.component';
import { SwiperDosimetriaComponent } from './dosimetria/swiper-dosimetria/swiper-dosimetria.component';
import { SwiperDosimetriaConfianComponent } from './dosimetria/swiper-dosimetria-confian/swiper-dosimetria-confian.component';
import { ConfianCardComponent } from './dosimetria/swiper-dosimetria-confian/confian-card/confian-card.component';
import { SociosComponent } from './socios/socios.component';
import { HeroSociosComponent } from './socios/hero-socios/hero-socios.component';
import { SwiperSociosComponent } from './socios/swiper-socios/swiper-socios.component';
import { CardSociosComponent } from './socios/swiper-socios/card-socios/card-socios.component';
import { HeroProfesionalesComponent } from './nosotros/nuestros-profesionales/hero-profesionales/hero-profesionales.component';
import { HeroMuseoComponent } from './nosotros/museo/hero-museo/hero-museo.component';
import { SwiperMuseoComponent } from './nosotros/museo/swiper-museo/swiper-museo.component';
import { CardMuseoComponent } from './nosotros/museo/swiper-museo/card-museo/card-museo.component';
import { ProcesoComponent } from './dosimetria/proceso/proceso.component';
import { ElementosComponent } from './dosimetria/elementos/elementos.component';
import { ElementosCardComponent } from './dosimetria/elementos/elementos-card/elementos-card.component';
import { ElementosCardSwiperComponent } from './dosimetria/elementos/elementos-card/elementos-card-swiper/elementos-card-swiper.component';
import { TldComponent } from './dosimetria/tld/tld.component';
import { FilmComponent } from './dosimetria/film/film.component';
import { NovedadesComponent } from './novedades/novedades.component';
import { NewsCardDestacadoComponent } from './novedades/news-card-destacado/news-card-destacado.component';
import { HeroNovedadesComponent } from './novedades/hero-novedades/hero-novedades.component';
import { SuscribiteComponent } from './common/suscribite/suscribite.component';
import { LoginComponent } from './login/login.component';
import { HeroLoginComponent } from './login/hero-login/hero-login.component';
import { CapacitateComponent } from './capacitate/capacitate.component';
import { HeroCapacitateComponent } from './capacitate/hero-capacitate/hero-capacitate.component';
import { CourseCardComponent } from './capacitate/course-card/course-card.component';
import { FaqComponent } from './faq/faq.component';
import { NoticiaComponent } from './novedades/noticia/noticia.component';
import { SuscribiteDialogComponent } from './common/suscribite/suscribite-dialog/suscribite-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfesionalesFormDialogComponent } from './nosotros/nuestros-profesionales/profesionales-form-dialog/profesionales-form-dialog.component';
import { SociosFormDialogComponent } from './socios/socios-form-dialog/socios-form-dialog.component';
import { UserHomeComponent } from './login/user-home/user-home.component';
import { UserSwiperAcquiredComponent } from './login/user-home/user-swiper-acquired/user-swiper-acquired.component';
import { UserSwiperGetComponent } from './login/user-home/user-swiper-get/user-swiper-get.component';
import { UserSwiperAcquiredCardComponent } from './login/user-home/user-swiper-acquired/user-swiper-acquired-card/user-swiper-acquired-card.component';
import { UserSwiperGetCardComponent } from './login/user-home/user-swiper-get/user-swiper-get-card/user-swiper-get-card.component';
import { HttpClientModule } from '@angular/common/http';
import { CommentComponent } from './novedades/noticia/comment/comment.component';
import { RegisterComponent } from './login/register/register.component';
import { EnConstruccionComponent } from './common/en-construccion/en-construccion.component';
import { EnConstruccionHeroComponent } from './common/en-construccion/en-construccion-hero/en-construccion-hero.component';


@NgModule({
  declarations: [
    AppComponent,
    NavmenuComponent,
    HomeComponent,
    HeroComponent,
    PresentationComponent,
    AccordionDosimetriaComponent,
    AccordionCapacitateComponent,
    AccordionProfesionalesComponent,
    AccordionFooterComponent,
    CarouselComponent,
    NewsCardComponent,
    SectionCardComponent,
    NosotrosComponent,
    SubmenuComponent,
    NuestrosProfesionalesComponent,
    MuseoComponent,
    ContactoComponent,
    HeroContactoComponent,
    HeroNosotrosComponent,
    SwiperNosotrosComponent,
    CardsNosotrosComponent,
    HistoriaComponent,
    HeroHistoriaComponent,
    DosimetriaComponent,
    HeroDosimetriaComponent,
    CardDosimetriaComponent,
    SwiperDosimetriaComponent,
    SwiperDosimetriaConfianComponent,
    ConfianCardComponent,
    SociosComponent,
    HeroSociosComponent,
    SwiperSociosComponent,
    CardSociosComponent,
    HeroProfesionalesComponent,
    HeroMuseoComponent,
    SwiperMuseoComponent,
    CardMuseoComponent,
    ProcesoComponent,
    ElementosComponent,
    ElementosCardComponent,
    ElementosCardSwiperComponent,
    TldComponent,
    FilmComponent,
    NovedadesComponent,
    NewsCardDestacadoComponent,
    HeroNovedadesComponent,
    SuscribiteComponent,
    LoginComponent,
    HeroLoginComponent,
    CapacitateComponent,
    HeroCapacitateComponent,
    CourseCardComponent,
    FaqComponent,
    NoticiaComponent,
    SuscribiteDialogComponent,
    ProfesionalesFormDialogComponent,
    SociosFormDialogComponent,
    UserHomeComponent,
    UserSwiperAcquiredComponent,
    UserSwiperGetComponent,
    UserSwiperAcquiredCardComponent,
    UserSwiperGetCardComponent,
    CommentComponent,
    RegisterComponent,
    EnConstruccionComponent,
    EnConstruccionHeroComponent,
  ],
  imports: [
    NgxSpinnerModule,
    HttpClientModule,
    NgxUsefulSwiperModule,
    MatSliderModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatMenuModule,
    MatDialogModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatSnackBarModule
  ],
  providers: [
    NewsService,
    SuscribiteService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
