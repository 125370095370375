<div class="card">
    <div class="card-type">{{ new.type }}</div>
    <div class="card-content">
        <div class="card-content-date">{{ new.creation_date | date: 'dd-MM-yyyy' }}</div>
        <div class="card-content-title"> <h2>{{ new.title }}</h2> </div>
        <div class="card-content-text" [innerHtml]="new.subtitle">
        </div>
        <div class="card-content-see-more"> 
            <span class=card-content-see-more-spacer></span> 
            <button mat-flat-button class=card-content-see-more-button
            [routerLink]="['/novedades/noticia', new.id]">
                <div class="card-content-see-more-button-text">
                   informate 
                </div> 
                <i class="card-content-see-more-button-square"></i>
            </button> 
        </div>
    </div>
</div>

