<header class=hero>
    <div class="hero-content">
        <div class="material-icons-outlined">
            account_circle
        </div>
        <div class="hero-text"> 
            <h1>USUARIOS</h1><h2>tu espacio en ATRR</h2>
        </div>  
    </div>
</header>
