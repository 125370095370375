import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  API_URI = 'https://api.atrr.org.ar/api'

  constructor(private http: HttpClient) { }

  getUser(id: string) {
    return this.http.get(`${this.API_URI}/users/${id}`);
  }

  updateUser(id: any, updatedUser: any): Observable<any> {
    return this.http.put(`${this.API_URI}/users/${id}`, updatedUser);
  } 
}
