<app-navmenu></app-navmenu>
<app-hero-historia class="hero-container"></app-hero-historia>
<div class="page-section">
    <div class="page-section-wrapper historia-body">
        <h2 class="historia-body-title">NUESTRA HISTORIA</h2>
    <h6>La historia es aquella experiencia de la cual mientras vivimos, 
        podemos ser parte, o simplemente mirarla como pasa a nuestro lado para 
        luego leerla o que nos la cuenten otros. La historia de la radiología 
        se inicia allá por el año 1.895 con el descubrimiento de los rayos X 
        por Wilhelm Conrad Röentgen.
    </h6>
    <h2 class=historia-body-subtitle>TODO COMENZÓ EN 1979</h2>
    <p>
        La nuestra, la que contaremos es un poco más reciente, comienza con el 
        cierre de nuestra carrera de Técnicos Radiólogos que se dictaba en la 
        Facultad de Ciencias Médicas de la U.N.R (Universidad Nacional de Rosario)
         y aún hoy nos preguntamos por qué la atroz dictadura cívico-militar  
         la cerró en 1977, egresando los últimos profesionales en 1979. Nunca 
         supimos con certeza porque nuestra carrera. ¿Por qué no Medicina o 
         Arquitectura o Psicología? Un revisionismo caprichoso diría que, o 
         tenían miedo a que nos convirtamos en “futuros terroristas radioactivos” 
         o alguna garra “negra, huesuda y peluda” tuvo injerencias nefastas. La 
         cuestión es que nos dejaron sin carrera.
        La versión científica de la genealogía dice que las generaciones se 
        extienden o transcurren en un lapso de 25 años, por lo que con mucha emoción 
        podemos decir que la A.T.R.R ya transita su segunda generación.
        
    </p>
    <div class="page-section-wrapper-img">
        <picture>
            <source srcset="../../../assets/nosotros/historia/web_nosotros_historia1.jpg" media="(min-width: 750px)">
            <img srcset="../../../assets/nosotros/historia/mobile_nosotros_historia1.jpg" alt="…">
        </picture>
    </div>
    <p class="epigrafe">Cartelería de nuestra primera casa. Año 1988.</p>
    <h3>Acontecimientos importantes</h3>
    <p>Con gruesas pinceladas te describimos lo realizado en los años hasta aquí 
        vividos desde nuestro nacimiento, un cuadro que seguramente continuará 
        siendo pintando hasta lograr el más valioso que merezcan nuestros colegas.
        Nacimos el 5 de diciembre de 1981. 2) Imprimimos desde el primer mes más
         de 120 Boletines de distribución gratuita entre sus asociados/as y 12 
         revistas “Imagen”. 3) Realizamos 18 Jornadas, 22 Cursos, junto a 
         F.A.T.Ra, 9 Congresos Argentinos, 2 Congresos internacionales. 
         4) Trabajamos y logramos la aprobación de una Ley de Ejercicio Profesional 
         y una Ley de Colegio Profesional. 5) Dictamos 3 Cursos de Normalización 
         profesional. 6) Adquirimos una casa propia. 7) Suscribimos convenios con 
         instituciones públicas y privadas, generando beneficios para nuestros/as 
         socios/as. 8) Enviamos colegas a Congresos Internacionales de 
         Radioprotección. 9) Creamos un departamento de Radioprotección y Dosimetría 
         que brinda servicios de Dosimetría a nivel nacional (film y TLD) con 
         habilitación, y aval de la A.R.N, el Ministerio de Salud y Radiofísica 
         Sanitaria. 10) Formamos, apoyamos y avalamos la creación legal de una 
         institución educativa con programas totalmente realizados por y para 
         profesionales en tecnologías del diagnóstico por imágenes y las terapias 
         radiantes, fue primero la Escuela del Hospital Provincial y posteriormente 
         el Instituto Superior de Tecnologías Médicas 11) Somos co-fundadores de 
         F.A.T.Ra y continuamos siendo parte de ella hasta el día de la fecha. 
         12) Cientos de socios/as fueron y son beneficiados con sorteos mensuales 
         de electrodomésticos, participan de círculos de ahorro, subsidios por 
         nacimiento y casamiento. 13) Damos cobijo a través de convenio al primer 
         Observatorio de la profesión del país (O.T.D.I.T.R.A). 14) Impulsamos, 
         formamos parte y ocupamos cargos directivos en A.L.A.T.R.A. 15) Creamos 
         el primer museo físico de elementos radiológicos de Licenciados y Técnicos 
         Radiólogos y le dimos el nombre de quién realizó la primera placa 
         radiográfica de Argentina el 12 de diciembre de 1896, el Dr. Tomás Varsi, 
         a quien hicimos nombrar ciudadano ilustre de Rosario por haberse desempeñado 
         muchos años en nuestra ciudad, a la vez que investigamos toda su vida.
         16) Adquirimos terrenos a 25 Km de Rosario donde ya estamos construyendo 
         nuestro propio Centro Recreativo. 16) Continuamos trabajando para generar 
         nuevas leyes que mejoren la calidad de vida de cada uno de los profesionales 
         de nuestra provincia.
        </p>
    </div>
</div>
<!-- <div class="know-more">
    <div class="content-wrapper">
        <div class="know-more-description">
        <p>Leé más sobre nuestra historia descargando el PDF</p>
        </div>
        <div class="know-more-second-row">
            <p>Querés saber más?</p> <span class=spacer></span>
            <button mat-flat-button class=know-more-button>descargá el PDF aquí</button>
        </div>
    </div>    
</div> -->

<div class="shadow-know-more"></div>

<div class="historia-asociate">
    <h3>FORMA PARTE DE 
        <!-- <br> -->
        NUESTRA HISTORIA <br> · UNITE A ATRR ·</h3>
    <button routerLink="/socios" mat-flat-button class=invitation-button>quiero ser parte</button>
</div>
<app-accordion-footer></app-accordion-footer>
