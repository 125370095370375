<h2 class="title">· CONFÍAN EN NOSOTROS ·</h2>

  <div class="swiper-confian-container">
    <!-- <h2 class="title">· CONFÍAN EN NOSOTROS ·</h2> -->
      <swiper [config]="config" style="height: 160px; width: 100%;" #myswiper>
          <div class="swiper-wrapper">
            <div class="swiper-slide"><app-confian-card></app-confian-card></div>
            <div class="swiper-slide"><app-confian-card></app-confian-card></div>
            <div class="swiper-slide"><app-confian-card></app-confian-card></div>
            <div class="swiper-slide"><app-confian-card></app-confian-card></div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
          <!-- Add Arrows -->
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </swiper>
  </div>

  <div class="cards-container">
  
    <div class="cards-container-desktop">
      <div class="cards-container-desktop-card">
        <app-confian-card></app-confian-card>
      </div>
      <div class="cards-container-desktop-card">
        <app-confian-card></app-confian-card>
      </div>
      <div class="cards-container-desktop-card">
        <app-confian-card></app-confian-card>
      </div>
      <div class="cards-container-desktop-card">
        <app-confian-card></app-confian-card>
      </div>
    </div>
  </div>





