import { Component, ElementRef, NgZone } from '@angular/core';
import { SwiperOptions } from 'swiper';
import ResizeObserver from 'resize-observer-polyfill';
import { getTranslationDeclStmts } from '@angular/compiler/src/render3/view/template';

@Component({
  selector: 'app-user-swiper-acquired',
  templateUrl: './user-swiper-acquired.component.html',
  styleUrls: ['./user-swiper-acquired.component.scss']
})
export class UserSwiperAcquiredComponent {
  
  configAcquired: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 0,
    centerInsufficientSlides: false,
    slidesPerView: "auto",
    centeredSlides: true,
    loopedSlides: 0,
    roundLengths: true,
    breakpoints: {
      750: {
        slidesPerView: 3,
        centeredSlides: false,
        centerInsufficientSlides: true,
      }
    }
  };
}
