<h2 class="title">COMISIÓN DIRECTIVA</h2>

  <div class="swiper-nosotros-container">
      <swiper [config]="config" style="height: 150px; width: 100%;" #myswiper>
          <div class="swiper-wrapper">
            <div class="swiper-slide"><app-cards-nosotros [nosotrosCardObject]="presidente"></app-cards-nosotros></div>
            <div class="swiper-slide"><app-cards-nosotros [nosotrosCardObject]="secretaria"></app-cards-nosotros></div>
            <div class="swiper-slide"><app-cards-nosotros [nosotrosCardObject]="tesorera"></app-cards-nosotros></div>
            <div class="swiper-slide"><app-cards-nosotros [nosotrosCardObject]="vocalTitular"></app-cards-nosotros></div>
            <div class="swiper-slide"><app-cards-nosotros [nosotrosCardObject]="vocalSuplente"></app-cards-nosotros></div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
          <!-- Add Arrows -->
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </swiper>
  </div>
  <div class="cards-container">
    <div class="cards-container-desktop">
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="presidente"></app-cards-nosotros>
      </div>
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="secretaria"></app-cards-nosotros>
      </div>
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="tesorera"></app-cards-nosotros>
      </div>
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="vocalTitular"></app-cards-nosotros>
      </div>
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="vocalSuplente"></app-cards-nosotros>
      </div>
    </div>
  </div>

  <!-- <div class="cards-container">
    <div class="cards-container-desktop">
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="presidente"></app-cards-nosotros>
      </div>
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="secretaria"></app-cards-nosotros>
      </div>
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="tesorera"></app-cards-nosotros>
      </div>
    </div>
  </div>

  <div class="cards-container">
    <div class="cards-container-desktop">
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="vocalTitular"></app-cards-nosotros>
      </div>
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="vocalSuplente"></app-cards-nosotros>
      </div>
    </div>
  </div> -->

  <h2 class="title">COMISIÓN <br class="only-mobile"> REVISORA DE CUENTAS</h2>

  <div class="swiper-nosotros-container">
      <swiper [config]="config" style="height: 150px; width: 100%;" #myswiper>
          <div class="swiper-wrapper">
            
            <div class="swiper-slide"><app-cards-nosotros [nosotrosCardObject]="titular1"></app-cards-nosotros></div>
            <div class="swiper-slide"><app-cards-nosotros [nosotrosCardObject]="titular2"></app-cards-nosotros></div>
            <div class="swiper-slide"><app-cards-nosotros [nosotrosCardObject]="suplente"></app-cards-nosotros></div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
          <!-- Add Arrows -->
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </swiper>
  </div>
  <div class="cards-container">
    <div class="cards-container-desktop">
      <span class="cards-container-desktop-card">
      </span>
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="titular1"></app-cards-nosotros>
      </div>
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="titular2"></app-cards-nosotros>
      </div>
      <div class="cards-container-desktop-card">
        <app-cards-nosotros [nosotrosCardObject]="suplente"></app-cards-nosotros>
      </div>
      <span class="cards-container-desktop-card">
      </span>
    </div>
  </div>



