<app-navmenu></app-navmenu>
<div class="page-section">
    <!-- <div class="page-section-wrapper" *ngFor="let game of games"> -->
    <div class="page-section-wrapper">
        <div class="news-header" id="news-start">
            <h4 class="news-date">{{ new.creation_date | date: 'dd-MM-yyyy' }}</h4>
            <h2>{{ new.title }}</h2>
            <h4 class="news-kind">{{ new.type }}</h4>
            <h4 class="news-excerpt">
                {{ new.subtitle }}
            </h4>
        </div>
        <div class="news-break">
            <div class="news-break-share">
                <ul>
                    <li>
                        <a href="https://api.whatsapp.com/send?text={{ href }}" 
                        data-action="share/whatsapp/share" target="_blank">
                            <img class="wap" src="../../../assets/novedades/whatsapp.svg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="mailto:?&subject=&body={{ href }}"><img class="mail" src="../../../assets/novedades/email-black-18dp.svg" alt=""></a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/sharer/sharer.php?u={{ href }}" target="_blank"><img class="fcbk" src="../../../assets/novedades/facebook-black-18dp.svg" alt=""></a>
                    </li>
                    <!-- <li>
                        <a href=""><img class="share"src="../../../assets/novedades/share-24px.svg" alt=""></a>
                    </li>    -->
                </ul>
            </div>
            <!-- <div class="news-break-author">
                <img src="../../../assets/nosotros/PRESIDENTE-HUGO-NESTOR-SAVARECIO-SOCIO-N-251.png" alt="">
                <div class="news-break-author-text">
                    <p>Hugo Savarecio</p>
                    <div class="news-break-author-text-occupation">
                        Presidente de la Asociación
                    </div>
                </div>
            </div> -->
        </div>
        <div class="noticia">
            <div class="noticia-content" [innerHtml]="new.description">
                <!-- {{ new.description }} -->
            </div>
        </div>
        
        
        <div class="news-break">
            <div class="news-break-share">
                <ul>
                    <li>
                        <a href="https://api.whatsapp.com/send?text={{ href }}" 
                        data-action="share/whatsapp/share" target="_blank">
                            <img class="wap" src="../../../assets/novedades/whatsapp.svg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="mailto:?&subject=&body={{ href }}"><img class="mail" src="../../../assets/novedades/email-black-18dp.svg" alt=""></a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/sharer/sharer.php?u={{ href }}" target="_blank"><img class="fcbk" src="../../../assets/novedades/facebook-black-18dp.svg" alt=""></a>
                    </li>
                    <!-- <li>
                        <a href=""><img class="share"src="../../../assets/novedades/share-24px.svg" alt=""></a>
                    </li>    -->
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="section-division"></div>
<div class="page-section">
    <div class="page-section-wrapper">
        <h3 class="comment-title">COMENTARIOS ·</h3>
        <p class="no-comments" *ngIf="noComments">Aún no hay comentarios, dejanos el tuyo!</p>
    </div>
</div>
<div class="comments-section" *ngFor="let comment of comments">
    <app-comment [comment]="comment"></app-comment>
</div>
<div class="page-section">
    <div class="page-section-wrapper">
        <form novalidate 
        [formGroup]="commentForm"
        (ngSubmit)="save($event)">
            <div class="first-row">
                <div class="first-row-inputs">
                    <div class="form-warn" *ngIf="commentForm.get('name').errors && commentForm.get('name').touched">
                        <small *ngIf="commentForm.get('name').hasError('required')">
                            Debes escribir tu nombre.
                        </small>
                    </div>
                    <mat-form-field appearance="fill" class="comment-form-field">
                        <!-- <mat-label>Tu nombre</mat-label> -->
                        <input matInput placeholder="Tu nombre" type="text" formControlName="name">
                    </mat-form-field>
                    
                    <mat-form-field appearance="fill" class="comment-form-field">
                        <!-- <mat-label>Tu especialidad</mat-label> -->
                        <input matInput placeholder="Tu especialidad" type="text" formControlName="job">
                    </mat-form-field>
                </div>
                <button mat-flat-button (click)="save($event)" class=comment-form-button>Publicar</button>
            </div>
            <div class="second-row">
                <div class="form-warn" *ngIf="commentForm.get('content').errors && commentForm.get('content').touched">
                    <small *ngIf="commentForm.get('content').hasError('required')">
                        Debes escribir un comentario.
                    </small>
                </div>
                <mat-form-field appearance="fill" class="comment-form-field-textarea">
                    <!-- <mat-label>Escribe tu comentario</mat-label> -->
                    <textarea rows="8" matInput placeholder="Escribe tu comentario" type="text" formControlName="content">
                    </textarea>
                </mat-form-field>  
            </div>
        </form>
        <div class="buttons">
            <!-- <a href="/novedades/noticia/#news-start" class="a-text">Ir arriba</a> -->
            <!-- <span> / </span> -->
            <a routerLink="/novedades" class="a-text">Volver a notas</a>
        </div>
    </div>
</div>
<div class="standard-shadow" *ngIf="newsNotThisLength>=1"></div>
<div class="page-section">
    <div class="page-section-wrapper otras-novedades" *ngIf="newsNotThisLength>=1">
        <h3>OTRAS <br>· NOVEDADES ·</h3>
        <div class="news-cards-container" *ngFor="let newNotThis of newsNotThis">
            <div class="news-cards-container-card"><app-news-card [new]="newNotThis"></app-news-card></div>
        </div>
    </div>
</div>
<app-suscribite></app-suscribite>

<app-accordion-footer></app-accordion-footer>