import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  API_URI = 'https://api.atrr.org.ar/api'

  constructor(private http: HttpClient) { }

  getComments() {
    return this.http.get(`${this.API_URI}/comments`);
  }

  getComment(id: number) {
    return this.http.get(`${this.API_URI}/comments/${id}`);
  }

  saveComment(comment: any) {
    return this.http.post(`${this.API_URI}/comments`, comment);
  }

  deleteComment(id: string) {
    return this.http.delete(`${this.API_URI}/comments/${id}`);
  }

  updateComment(id: string, updatedComment: any): Observable<any> {
    return this.http.put(`${this.API_URI}/comments/${id}`, updatedComment);
  } 
}
