import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-swiper-dosimetria',
  templateUrl: './swiper-dosimetria.component.html',
  styleUrls: ['./swiper-dosimetria.component.scss']
})
export class SwiperDosimetriaComponent{

  tldObject = {
    imgUrl: "../../../assets/dosimetria/mobile_dosimetria_img_tld.jpg",
    name: "TLD",
    description: "Formado por cristales que reaccionan generando luz ante la radiación de rayos x o gamma. La dosis recibida se calcula a partir de la cantidad de luz emitida. El TLD se utiliza en actividades de mayor exposición.",
    link: "/dosimetria/tld",
  }

  filmObject = {
    imgUrl: "../../../assets/dosimetria/mobile_dosimetria_img_film.jpg",
    name: "DOSÍMETRO DE FILM",
    description: "Una película que expuesta a radiación ionizante se ennegrece en una placa con diferentes campos de filtros, la medición se realiza a través de la diferenciación de niveles de radiación más y menos penetrantes.",
    link: "/dosimetria/film",
  }

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 15,
    //centerInsufficientSlides: true,
    //loop: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    loopedSlides: 0,
    roundLengths : true,
  };
}