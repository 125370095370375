import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-accordion-dosimetria',
  templateUrl: './accordion-dosimetria.component.html',
  styleUrls: ['./accordion-dosimetria.component.scss']
})
export class AccordionDosimetriaComponent implements OnInit {
  @Input('homeAccordion') 
  homeAccordion:[];

  constructor() { }

  ngOnInit(): void {
  }

}
