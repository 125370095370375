import { New } from './../../../models/New';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss']
})
export class NewsCardComponent implements AfterContentInit {
  @Input('new') 
  new: any;
  noImage: any;
  noImageNoHTML: any;
  noImageNoHTMLShorten: any;


  // imgReplacement() {
  //   //this.new.description.replace(/<[^>]*>/g, '\n');
  //   var regX = /(<([^>]+)>)/ig;                
  //   alert(this.new.description.replace(regX, ""));
  // }

  constructor() { 
    // this.imgReplacement();
    //this.noImage = this.new.description.replace(/<img[^>]*>/gi,"");
  }

  ngAfterContentInit(){             
    //this.noImage = this.new.description.replace(/<img[^>]*>/gi,"");
    this.noImage = this.new.description.replace(/<img[^>]*>/gi,"");
    this.noImageNoHTML = this.noImage.replace(/<(?:.|\n)*?>/gm, ' ');
    //this.noImageShorten = this.noImageNoHTML.replace(/^(.{100}[^\s]*).*/, "$1") + "\n";
    this.noImageNoHTMLShorten = this.shorten(this.noImageNoHTML, 160) + " ..."
  }

  shorten(str, maxLen, separator = ' ') {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen));
  }

}
