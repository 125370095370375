import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero-contacto',
  templateUrl: './hero-contacto.component.html',
  styleUrls: ['./hero-contacto.component.scss']
})
export class HeroContactoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
