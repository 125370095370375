import { AfterContentInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperOptions } from 'swiper';


@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent { 

  @Input('news')
  news: [];

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 0,
    //centerInsufficientSlides: true,
    //loop: true,
    slidesPerView: "auto",
    centeredSlides: true,
    loopedSlides: 0,
    roundLengths : true,
  };
}
