<section class="suscribite" id="suscribite">
    <div class=grey-shadow>
        <p></p>
    </div>
    <h3>
        SUSCRIBITE PARA RECIBIR NUESTRAS NOVEDADES 
        <!-- <b>·</b> -->
    </h3>
    <div class="suscribite-white-block">
        <div class="suscribite-description">
            <p>Dejanos tu mail y enterate antes que nadie de las novedades!</p>
        </div>
    </div>
    <form novalidate 
    [formGroup]="subscribeForm"
    (ngSubmit)="save($event)">
        <mat-form-field appearance="fill" class="email-form">
        <mat-label>e-mail</mat-label>
        <input matInput placeholder="tuemail@mail.com" type="text" formControlName="email">
        </mat-form-field>
        <div class="form-warn" *ngIf="subscribeForm.get('email').errors && subscribeForm.get('email').touched">
            <small *ngIf="subscribeForm.get('email').hasError('email')">
            Debe ser un E-mail válido.
            </small>
            <small *ngIf="subscribeForm.get('email').hasError('required')">
                Debes escribir tu E-mail.
            </small>
        </div> 
    </form>
    <button mat-flat-button (click)="save($event)" class=suscribite-button>enviar</button> 
    
    
</section>