import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-user-swiper-get',
  templateUrl: './user-swiper-get.component.html',
  styleUrls: ['./user-swiper-get.component.scss']
})
export class UserSwiperGetComponent {

  configUserGet: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    autoplay: {
      delay: 7000,
      disableOnInteraction: true,
    },
    spaceBetween: 0,
    centerInsufficientSlides: false,
    //loop: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    loopedSlides: 0,
    roundLengths : true,
    breakpoints: {
      750: {
        slidesPerView: 3,
        centeredSlides: false,
        centerInsufficientSlides: true,
      }
    }
  };


}
