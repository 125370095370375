<div class="card">
    <div class="card-img-container-custom" *ngIf="!noImagePost">
        <!-- <img src="../../../assets/profile/mobile_banner_perfil.jpg" alt=""> -->
        <img class="card-img-container-custom-img" *ngIf="!noImagePost" src="https://api.atrr.org.ar{{ parteSinSrc }}" alt="">
        <!-- <img class="card-img-container-standard-img" *ngIf="noImagePost" src="../../../assets/profile/mobile_banner_perfil.jpg" alt=""> -->
    </div>
    <div class="card-img-container-standard" *ngIf="noImagePost">
        <!-- <img src="../../../assets/profile/mobile_banner_perfil.jpg" alt=""> -->
        <!-- <img class="card-img-container-custom-img" *ngIf="!noImagePost" src="http://localhost:3000{{ parteSinSrc }}" alt=""> -->
        <img class="card-img-container-standard-img"  src="../../../assets/profile/mobile_banner_perfil.jpg" alt="">
    </div>
    <div class="card-type">{{ highlighted.type }}</div>
    <div class="card-content">
        <div class="card-content-date">{{ highlighted.creation_date | date: 'dd-MM-yyyy' }}</div>
        <div class="card-content-title"> <h2>{{ highlighted.title }}</h2> </div>
        <!-- <div class="card-content-text" [innerHtml]="noImageNoHTMLShorten">
        </div>  -->
        <div class="card-content-text" [innerHtml]="highlighted.subtitle">
        </div>  
    </div>
    <div class="card-content-see-more"> 
        <a [routerLink]="['/novedades/noticia', highlighted.id]" class=card-content-see-more-button>
            <div class="card-content-see-more-button-text">
               informate 
            </div> 
            <i class="card-content-see-more-button-square"></i>
        </a> 
    </div>
</div>