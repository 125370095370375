import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-en-construccion-hero',
  templateUrl: './en-construccion-hero.component.html',
  styleUrls: ['./en-construccion-hero.component.scss']
})
export class EnConstruccionHeroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
