<div class="elementos-card">
    <div class="elementos-card-img">
        <app-elementos-card-swiper [elementosObject]="elementosObject"></app-elementos-card-swiper>     
    </div>
    <div class="elementos-card-content">
        <div class="elementos-card-content-text"> 
            <p>{{ elementosObject.name }}</p> 
        </div>
        <div class="elementos-card-content-text"> 
            <p>
                {{ elementosObject.description }}
            </p> 
        </div>
        <div class="elementos-card-content-see-more"> 
            <!-- <span class=elementos-card-content-see-more-spacer></span>  -->
            <button routerLink="/contacto" mat-flat-button class=elementos-card-content-see-more-button>
                <div class="elementos-card-content-see-more-button-text">
                   consultar
                </div> 
                <i class="elementos-card-content-see-more-button-square"></i>
            </button> 
        </div>
    </div>
</div>
