<div class="card">
    <div class="card-paginator">
        <i [ngClass]="{dot: dosimetroObject.name!='TLD', notdot: dosimetroObject.name=='TLD'}"></i>
        <i [ngClass]="{dot: dosimetroObject.name=='TLD', notdot: dosimetroObject.name!='TLD'}"></i>
    </div>
    <div class="card-img-container">
        <img src="{{ dosimetroObject.imgUrl }}" alt="">
    </div>
    <div class="card-type">{{ dosimetroObject.name }}</div>
    <div class="card-content">
        <div class="card-content-text"> <p>{{ dosimetroObject.description }}</p> 
        </div>
        <div class="card-content-see-more"> 
            <!-- <span class=card-content-see-more-spacer></span>  -->
            <a routerLink="{{ dosimetroObject.link }}" class=card-content-see-more-button>
                <div class="card-content-see-more-button-text">
                   ver + 
                </div> 
                <i class="card-content-see-more-button-square"></i>
            </a> 
        </div>
    </div>
</div>
