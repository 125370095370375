<section>
    <p></p>
</section>

<mat-accordion>
    <mat-expansion-panel class=panel>
      <mat-expansion-panel-header class="menu">
        <mat-panel-title class="title">
          CAPACITATE
        </mat-panel-title>
        <mat-panel-description>
          <!-- {{panelOpenState ? 'open' : 'closed'}} -->
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="name">
        <h3>CURSOS ATRR</h3>
        <h3></h3>
      </div>
      <p>
        Sabemos que lo mas importante es no dejar de capacitarse. 
        Por eso emos creado los CURSOS ATRR. 
        Descubrí que tenemos para vos!
        </p>
    </mat-expansion-panel>
  </mat-accordion>
  
