import { CommentsService } from './../../services/comments.service';
import { NewsService } from './../../services/news.service';
import { Component, OnInit } from '@angular/core';
import { New } from 'src/app/models/New';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProfesionalesFormDialogComponent } from '../../nosotros/nuestros-profesionales/profesionales-form-dialog/profesionales-form-dialog.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.scss']
})
export class NoticiaComponent implements OnInit {
  href: string = "";
  commentForm: FormGroup;
  new: New = {
    "creation_date": new Date,
  }
  comments: Array<any>;
  noComments: boolean = false;
  newsNotThis: Array<any>;
  newsNotThisLength: any;

  constructor(public dialog: MatDialog, 
    private newsService: NewsService, 
    private commentsService: CommentsService,
    private activatedRoute: ActivatedRoute) { 
    this.buildForm();
  }

  openDialog() {
    this.dialog.open(ProfesionalesFormDialogComponent);
  }

  ngOnInit(): void {
    const params = this.activatedRoute.snapshot.params;
    this.newsService.getNew(params.id).subscribe(
      res => {
        this.new = res;
        this.new.description = this.new.description.replace(/src="/g, 'src="https://api.atrr.org.ar');
      },
      err => console.error(err)
    );
    this.commentsService.getComment(params.id).subscribe(
      (res: Array<any>) => {
        console.log(res)
        this.comments = res;
        if (this.comments.length==0) {
          this.noComments = true;
        }
      },
      err => console.error(err)
    );
    this.newsService.getNews().subscribe(
      (res: Array<any>) => {
        this.newsNotThis = res.filter(_new => {
          return _new.id!=params.id;
        });
        this.newsNotThisLength = this.newsNotThis.length
      },
      err => console.error(err)
    );
    this.href = window.location.href;
    //console.log(this.href)
  }

  private buildForm() {
    this.commentForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      job: new FormControl(''),
      content: new FormControl('', [Validators.required]),
    });
  }

  save(event: Event) {
    event.preventDefault();
    if (this.commentForm.valid) {
      const value = this.commentForm.value;
      value['news_id']=this.new.id;
      console.log(value);
      this.commentsService.saveComment(value)
        .subscribe(
          res => {
            console.log(res);
          },
          err => console.log(err)
        )
      //this.openDialog();
      window.location.reload();
    } else {
      this.commentForm.markAllAsTouched();
    }
  }
}

