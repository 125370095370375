<app-navmenu></app-navmenu>
<app-hero-login class="hero-container"></app-hero-login>

<div class="page-section">
    <div class="page-section-wrapper">
        <h2>· INGRESÁ A TU PERFIL ·</h2>
        <form novalidate 
        [formGroup]="loginForm" class="login-form">
            <div class="login-form-wrapper">
                <mat-form-field appearance="outline" class="">
                    <input matInput type="text" placeholder="correo electrónico" formControlName="username">
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                    <input matInput type="password" placeholder="contraseña" formControlName="password">
                </mat-form-field>
                <a class=login-form-pass routerLink="/contacto">¿Olvidaste la contraseña?</a>
            </div>
        </form>
        <div class="login-form-buttons">
            <button mat-flat-button class=login-form-buttons-ingresar (click)="save($event)">ingresar</button>
            <button mat-flat-button class=login-form-buttons-registrate routerLink="/login/registrate">registrate</button>
        </div>
        <div class="login-form-help-link"> 
            <a routerLink="/contacto">
                <span class="desktop">Necesito ayuda</span> 
                <span class="mobile">?</span>   
            </a> 
        </div>
    </div>
</div>

<app-accordion-footer></app-accordion-footer>