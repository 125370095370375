<header class=hero>
    <div class="hero-content">
        <div class="logo">
            <p></p>
        </div>
        <div class="hero-text"> 
            <h1>NOSOTROS</h1><h2>Nuestro museo</h2>
        </div>  
    </div>
</header>

