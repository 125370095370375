import { SuscribiteService } from './../../services/suscribite.service';
import { SuscribiteDialogComponent } from './suscribite-dialog/suscribite-dialog.component';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-suscribite',
  templateUrl: './suscribite.component.html',
  styleUrls: ['./suscribite.component.scss']
})
export class SuscribiteComponent implements OnInit {
  subscribeForm: FormGroup;

  constructor(public dialog: MatDialog, private suscribiteService: SuscribiteService) { 
    this.buildForm();
  }

  openDialog() {
    this.dialog.open(SuscribiteDialogComponent);
  }

  ngOnInit(): void {
  }

  private buildForm() {
    this.subscribeForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required]),
    });
  }

  save(event: Event) {
    event.preventDefault();
    if (this.subscribeForm.valid) {
      const value = this.subscribeForm.value;
      console.log(value);
      this.suscribiteService.saveSubscribe(value)
        .subscribe(
          res => {
            console.log(res);
          },
          err => console.log(err)
        )
      this.openDialog();
    } else {
      this.subscribeForm.markAllAsTouched();
    }
  }

}
