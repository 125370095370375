import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-socios',
  templateUrl: './card-socios.component.html',
  styleUrls: ['./card-socios.component.scss']
})
export class CardSociosComponent implements OnInit {
  @Input('sociosObject')
  sociosObject:any;

  constructor() { }

  ngOnInit(): void {
  }

}
