<header class=hero>
    <div class="hero-content">
        <div class="material-icons">
            mail_outline
        </div>
        <div class="hero-text"> 
            <h1> CONTACTO</h1><h2>Comunicate con nosotros</h2>
        </div>  
    </div>
</header>
