import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-capacitate',
  templateUrl: './accordion-capacitate.component.html',
  styleUrls: ['./accordion-capacitate.component.scss']
})
export class AccordionCapacitateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
