import { New } from './../models/New';
import { NewsService } from './../services/news.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-novedades',
  templateUrl: './novedades.component.html',
  styleUrls: ['./novedades.component.scss']
})
export class NovedadesComponent implements OnInit {

  notHighlighted: any;
  highlighted: Array<any> = [];
  notHilhlightedLength: any;
  arrayStart: any = 0;
  arrayEnd: any = 3;
  notHighlightedShow: any;

  constructor(private newsService: NewsService) { }

  showMore() {
    if (this.notHilhlightedLength > (this.arrayEnd - 3) ) {
      this.notHighlightedShow = this.notHighlighted.slice(this.arrayStart, this.arrayEnd); 
        //console.log(this.notHighlightedShow);
        //this.arrayStart+=3;
        this.arrayEnd+=3;
    }
  }

  ngOnInit(): void {
    this.newsService.getNews().subscribe(
      (res: Array<any>) => {
        this.notHighlighted = res.filter(_new => {
          return _new.highlighted==0;
        });
        this.highlighted = res.filter( _new => {
          return _new.highlighted==1;
        });
        this.notHilhlightedLength = this.notHighlighted.length;
        this.notHighlightedShow = this.notHighlighted.slice(this.arrayStart, this.arrayEnd); 
        //console.log(this.notHighlightedShow);
        //this.arrayStart+=3;
        this.arrayEnd+=3;

        // () => {
        //   this.arrayStart+=3;
        //   this.arrayEnd+=3;
        // }
        // if (this.notHilhlightedLength > this.arrayEnd) {

        // }

      },
      err => console.error(err)
    );
  }

}
