import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-swiper-socios',
  templateUrl: './swiper-socios.component.html',
  styleUrls: ['./swiper-socios.component.scss']
})
export class SwiperSociosComponent {
  charlasObject = {
    imgUrl: "../../../../assets/socios/mobile_socios_charlas.jpg",
    title: "CHARLAS FORMATIVAS",
    description: "Nuevas modalidades, con aval en nuestra institución o a través de nuestra plataforma.",
    class: "",
  }

  becasObject = {
    imgUrl: "../../../../assets/socios/mobile_socios_becas.jpg",
    title: "BECAS TOTALES O PARCIALES",
    description: "Para congresos, viajes o cursos de especialización. Antigüedad requerida.",
    class: "",
  }

  sorteosObject = {
    imgUrl: "../../../../assets/socios/mobile_socios_sorteo.jpg",
    title: "SORTEOS MENSUALES",
    description: "Con tu número de socio/a todos los meses por la lotería Oficial de la provincia de Santa Fe.",
    class: "",
  } 

  descuentosObject = {
    imgUrl: "../../../../assets/socios/mobile_socios_congreso.jpg",
    title: "DESCUENTOS EN CONGRESOS",
    description: "Importantes descuentos en la inscripción a todos los eventos realizados por nuestra Federación u otras instituciones que la conforman.",
    class: "-descuentos",
  } 

  descDosimetriaObject = {
    imgUrl: "../../../../assets/socios/mobile_socios_descuentosdosimetria.jpg",
    title: "DESCUENTO ESPECIAL EN SERVICIO DE DOSIMETRÍA",
    description: "Solicitado a nombre del asociado/a.",
    class: "",
  } 
  complejoObject = {
    imgUrl: "../../../../assets/socios/mobile_socios_centror.jpg",
    title: "COMPLEJO RECREATIVO",
    description: "Para que disfruten el socio y su grupo familiar.",
    class: "-complejo",
  } 

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 15,
    //centerInsufficientSlides: true,
    loop: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    loopedSlides: 0,
    roundLengths : true,
  };
}
