<div class="card">

    <div class="card-img-container">
        <img src="{{ sociosObject.imgUrl }}" alt="">
    </div>
    
    <div class="card-content">
        <div class="card-content-text{{ sociosObject.class }}"> 
            <p>{{ sociosObject.title }}</p>
               <p> {{ sociosObject.description }}</p> 
        </div>
    </div>

    <div class="card-tag{{ sociosObject.class }}">
        PROXIMAMENTE
    </div>
</div>
