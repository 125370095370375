import { ContactForm } from './../../models/ContactForm';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  passwordConf: boolean = false;
  usernameConf: boolean = false;

  constructor(private authService : AuthService, private router : Router) { 
    this.buildForm();
  }

  ngOnInit(): void {
    
  }

  private buildForm() {
    this.registerForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.email, Validators.required]),
      usernameconf: new FormControl('', [Validators.email, Validators.required]),
      phone_number: new FormControl(''),
      country: new FormControl(''),
      province: new FormControl(''),
      city: new FormControl(''),
      password: new FormControl('', [Validators.required]),
      passwordconf: new FormControl('', [Validators.required]),
    });
  }

  save(event: Event) {
    this.usernameConf=false;
    this.passwordConf=false;

    event.preventDefault();
    if (this.registerForm.value.password != this.registerForm.value.passwordconf 
      || this.registerForm.value.username != this.registerForm.value.usernameconf) {
        if (this.registerForm.value.password != this.registerForm.value.passwordconf) {
          console.log('Las contraseñas no coinciden');
          this.passwordConf = !this.passwordConf;
          console.log(this.passwordConf);
        }
        if (this.registerForm.value.username != this.registerForm.value.usernameconf) {
          console.log('Las emails no coinciden');
          this.usernameConf = !this.usernameConf;
          console.log(this.usernameConf);
        }
      return
    }
    if (this.registerForm.valid) {
      delete this.registerForm.value.passwordconf;
      delete this.registerForm.value.usernameconf;
      const value = this.registerForm.value;
      value['user_type']=4;
      console.log(value)
      this.authService.registerUser(JSON.stringify(value))
      .subscribe(data => {
        this.authService.setUserInfo(data);
        this.router.navigate(['/login/user-home']);
        console.log(data)
      },
        error => console.log(error))
    } else {
      this.registerForm.markAllAsTouched();
      return;
    }
  }
}
