<div class="card">
    <div class="card-type">CURSO NUEVO 1</div>
    <div class="card-content">
        <div class="card-content-text"> 
            <p>Supercurso de radiología molecular intensiva. 
                No dejes de aprender esta tendencia.</p> 
        </div>
        <div class="card-content-see-more"> 
            <!-- <span class=card-content-see-more-spacer></span>  -->
            <a routerLink="" class=card-content-see-more-button>
                <div class="card-content-see-more-button-text">
                   Ir a la capacitación
                </div> 
                <i class="card-content-see-more-button-square"></i>
            </a> 
        </div>
    </div>
</div>