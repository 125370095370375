import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-swiper-dosimetria-confian',
  templateUrl: './swiper-dosimetria-confian.component.html',
  styleUrls: ['./swiper-dosimetria-confian.component.scss']
})
export class SwiperDosimetriaConfianComponent {
  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 60,
    //centerInsufficientSlides: true,
    loop: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    loopedSlides: 0,
    roundLengths : true,
  };
}
