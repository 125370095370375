import { Component, OnInit, Input } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-elementos-card-swiper',
  templateUrl: './elementos-card-swiper.component.html',
  styleUrls: ['./elementos-card-swiper.component.scss']
})
export class ElementosCardSwiperComponent {
  @Input('elementosObject')
  elementosObject:any;
  

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 15,
    //centerInsufficientSlides: true,
    //loop: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    loopedSlides: 0,
    roundLengths : true,
  };
}