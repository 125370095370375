import { Component, OnInit } from '@angular/core';
import { NewsService } from './../services/news.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  news: any;
  newss: any;

  homeAccordionDosimetria: Array<any>=[
    { title: "DOSIMETRÍA" ,
     mobileImg: "../../../assets/home/mobile_home_img_dosimetria.jpg" ,
     imgAlt: "ATRR dosimetría santa fe rosario radiología" ,
     name: "SERVICIOS DE DOSIMETRÍA" ,
     description: "La salud de nuestros profesionales es nuestra prioridad. Por eso en ATRR nos hemos especializado para garantizar una medición fiable." ,
     router: "/dosimetria",
     desktopImg: "../../../assets/home/web_home_img_dosimetria.jpg",  
     link: "informate",
     class: "",
    }
  ];
  homeAccordionCapacitate: Array<any>=[
    { title: "CAPACITATE" ,
     mobileImg: "../../../assets/home/mobile_home_img_capacitacionesatrr.jpg" ,
     imgAlt: "capacitate ATRR, capacitación radiológica Rosario Argentina" ,
     name: "CURSOS ATRR" ,
     description: "Sabemos que lo más importante es no dejar de capacitarse. Por eso hemos creado los CURSOS ATRR. Descubrí qué tenemos para vos!" ,
     router: "/capacitate",
     desktopImg: "../../../assets/home/web_home_img_capacitateatrr.jpg",
     link: "comenzá",
     class: "-inverted",
    }
  ];
  homeAccordionProfesionales: Array<any>=[
    { title: "PROFESIONALES" ,
     mobileImg: "../../../assets/home/mobile_home_img_profesionales.jpg" ,
     imgAlt: "profesionales radiológicos radiología rosario argentina santa fe" ,
     name: "NUESTROS PROFESIONALES" ,
     description: "Encontrá el profesional que estas buscando en nuestra amplia base de datos." ,
     router: "/nosotros/nuestros-profesionales",
     desktopImg: "../../../assets/home/web_home_img_profesionales.jpg", 
     link: "descubrilos",
     class: "",

    }
  ];
    

  constructor(private newsService: NewsService) { }

  ngOnInit(): void {
    this.newsService.getNews().subscribe(
      res => {
        this.newss = res;
        this.news = this.newss.slice(0, 3)

        //console.log(this.news)
        //console.log(this.news[0])
      },
      err => console.error(err)
    )
  }

}
