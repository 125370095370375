import { ContactService } from './../services/contact.service';
import { ProfesionalesFormDialogComponent } from './../nosotros/nuestros-profesionales/profesionales-form-dialog/profesionales-form-dialog.component';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  contactForm: FormGroup;

  constructor(public dialog: MatDialog, private contactService: ContactService) { 
    this.buildForm();
  }

  openDialog() {
    this.dialog.open(ProfesionalesFormDialogComponent);
  }
  
  ngOnInit(): void {
  }

  private buildForm() {
    this.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      phone_number: new FormControl('', [Validators.required]),
      comment: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email, Validators.required]),
    });
  }

  save(event: Event) {
    event.preventDefault();
    if (this.contactForm.valid) {
      const value = this.contactForm.value;
      // value['request_type']="dosimetria";
      console.log(value);
      this.contactService.saveContact(value)
        .subscribe(
          res => {
            console.log(res);
          },
          err => console.log(err)
        )
      this.openDialog();
    } else {
      this.contactForm.markAllAsTouched();
    }
  }
}
