import { Component, OnInit, Input } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-swiper-nosotros',
  templateUrl: './swiper-nosotros.component.html',
  styleUrls: ['./swiper-nosotros.component.scss']
})
export class SwiperNosotrosComponent {
  presidente = {
    imgUrl: "../../../../assets/nosotros/PRESIDENTE-HUGO-NESTOR-SAVARECIO-SOCIO-N-251.png",
    name: "Adriana Re",
    position: "Presidente",
    number: "1580",
  }
  secretaria = {
    imgUrl: "../../../../assets/nosotros/SECRETARIA-IVANA-ANDREA-MAURO-SOCIA-N-1416.png",
    name: "Carlos Tovar",
    position: "Secretario",
    number: "0260",
  }
  tesorera = {
    imgUrl: "../../../../assets/nosotros/TESORERA-LORENA-CARLA-KOWALEWSKI-SOCIA-N-618.png",
    name: "Lorena Kowalewski",
    position: "Tesorera",
    number: "0618",
  }
  vocalTitular = {
    imgUrl: "../../../../assets/nosotros/VOCAL-TITULAR-RAUL-HORACIO-BESAZZA-SOCIO-N-125.png",
    name: "Lorena Muzulin",
    position: "Vocal titular",
    number: "1009",
  }
  vocalSuplente = {
    imgUrl: "../../../../assets/nosotros/VOCAL-SUPLENTE-GABRIELA-INES-FIORUCCI-SOCIA-N-1257.png",
    name: "Cecilia Centurión",
    position: "Vocal suplente",
    number: "1314",
  }
  titular1 = {
    imgUrl: "../../../../assets/nosotros/LYDIA-SUSANA-CURIESES-SOCIA-N-151.png",
    name: "Verónica Digilio",
    position: "Titular",
    number: "0473",
  }
  titular2 = {
    imgUrl: "../../../../assets/nosotros/ADRIANA-ALICIA-RE-SOCIA-N-1580.png",
    name: "Claudio Nieto",
    position: "Titular",
    number: "0468",
  }
  suplente = {
    imgUrl: "../../../../assets/nosotros/BIANCA-SILVIA-LOCATELLI-SOCIA-N-1599.png",
    name: "Bianca Locatelli",
    position: "Suplente",
    number: "1599",
  }


  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 60,
    //centerInsufficientSlides: true,
    loop: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    loopedSlides: 0,
    roundLengths : true,
  };
}
