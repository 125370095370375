<div class="swiper-swiper"><div class="swiper-elementos-container">
    <swiper [config]="config" style="height: 300px; width: 300px;" #myswiper>
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img src="{{ elementosObject.imgUrl1 }}" 
        alt="">
      </div>
      <div class="swiper-slide">
        <img src="{{ elementosObject.imgUrl2 }}" 
        alt="">
      </div>
    </div>
    
    <!-- Add Pagination -->
    <div class="swiper-pagination"></div>
    <!-- Add Arrows -->
    <!-- <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
  </swiper>
  </div></div>