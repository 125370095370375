<div class="course-card">
    <div class="course-card-wrapper">
        <div class="course-card-content">
            <div class="course-card-content-code">
                Código de la capacitación ##
            </div>
            <div class="course-card-content-title">
                <h2>TÍTULO O NOMBRE DE LA CAPACITACIÓN 1</h2> 
            </div>
            <div class="course-card-content-img">
                <a class="material-icons">
                    play_circle_outline
                </a>    
                <img src="../../../assets/capacitate/course-card/rectangulo-74.png" alt="">
            </div>
            <div class="course-card-content-epigrafe">
                Modalidad: Clase grabada 
                <span class="desktop"> · </span> 
                <br class="mobile">
                Duración aprox. 2hs
            </div>
            <div class="course-card-content-description">
               <p>Intro o descripción de la capacitación sed diam 
                nonummy nibh euismod tincidunt ut laoreet 
                dolore magna aliquam erat volutpat. Ut wisi enim 
                ad minim veniam, quis nostrud exerci tation 
                ullamcorp sed diam nonummy nibh euismod tincidunt 
                ut laoreet dolore magna</p> 
            </div>
            <div class="course-card-content-link">
                <a href="">
                    <i class="course-card-content-link-square"></i>
                    <div class="course-card-content-link-text">
                        informate
                    </div>     
                </a>
            </div> 
               
        </div>
    </div>
</div>