<app-navmenu></app-navmenu>
<app-hero-museo class=hero-container></app-hero-museo>
<div class="page-section">
    <div class="page-section-wrapper page-section-museo">
        <h2>· NUESTRO MUSEO ·</h2>
        <p>
            En ATRR tenemos la vacación de conservar la historia. 
            No solo la nuestra sino la de todos nuestros colegas y nuestra profesión. 
            Por eso desde el año 2014 hemos fundado un museo en nuestro 
            establecimiento, donde poseemos innumerables elementos que se utilizaron 
            alguna vez para realizar la actividad que hoy ayuda a definirnos. 
            Te mostramos algunos de las más interesantes.
        </p>
    </div>
</div>
<div class="page-section">
    <div class="page-section-wrapper page-section-museo">
        <h2>· MUSEO TOMÁS VARSI ·</h2>
        <div class="page-section-wrapper-img">
            <picture>
                <source srcset="../../../assets/nosotros/museo/museo-portada-desktop.jpg" media="(min-width: 750px)">
                <img srcset="../../../assets/nosotros/museo/museo-portada-mobile.jpg" alt="…">
            </picture>
        </div>
    </div>
</div> 

<div class="swiper-museo-container">
    <app-swiper-museo></app-swiper-museo>
</div>


<mat-accordion>
    <mat-expansion-panel class="expansion-dosimetria" [disabled]="expansionDisabled" [expanded]="expansionExpanded">
      <mat-expansion-panel-header class="menu-header">
        <mat-panel-title class="title">
            ¿QUIÉN FUE EL DR. VARSI?
        </mat-panel-title>
        <mat-panel-description>
          <!-- {{panelOpenState ? 'open' : 'closed'}} -->
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="page-section">
        <div class="page-section-wrapper">
            <p class="sub">
            Generalmente, Varsi es mencionado exclusivamente en la polémica 
            relativa a quién obtuvo la primera radiografía en la Argentina; 
            sin embargo, en radiología su aporte más relevante fue la decisión 
            de utilizar el método radiográfico con fines diagnósticos.
            </p>
        </div>
      </div>
    </mat-expansion-panel>
</mat-accordion>

<div class="standard-shadow"></div>

<div class="page-section page-section-museo-alt">
    <div class="page-section-wrapper">
        <h2>EL MUSEO SIGUE CRECIENDO</h2>
        <p>
            Si tenés objetos que quisieras donar para contribuir al crecimiento 
            del museo Dr. Tomás Varsi, conéctate con la Asociación. 
            Queremos seguir creciendo para poder preservar la memoria de la 
            profesión en el país. Con tu ayuda podemos hacerlo.
        </p>
        <a routerLink="/contacto">
            <h5>¿Querés donar objetos? ¡Contactate con nosotros!</h5>
            <span class="material-icons">
            keyboard_arrow_right_round
            </span>
        </a>
    </div>
</div>

<app-accordion-footer></app-accordion-footer>