import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-dosimetria',
  templateUrl: './card-dosimetria.component.html',
  styleUrls: ['./card-dosimetria.component.scss']
})
export class CardDosimetriaComponent implements OnInit {
  @Input('dosimetroObject')
  dosimetroObject: any;

  constructor() { }

  ngOnInit(): void {
  }

}
