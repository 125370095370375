
<div class="swiper-home-container">
  <swiper [config]="config" style="height: 424px; width: 100%;" #myswiper>
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let new of news"> 
      <app-news-card [new]="new"></app-news-card> 
    </div>
    <!-- <div class="swiper-slide"> 
      <app-news-card [new]="news[0]"></app-news-card> 
    </div>
    <div class="swiper-slide"> 
      <app-news-card [new]="news[1]"></app-news-card> 
    </div>
    <div class="swiper-slide"> 
      <app-news-card [new]="news[2]"></app-news-card> 
    </div> -->
  </div>
  
  <!-- Add Pagination -->
  <div class="swiper-pagination"></div>
  <!-- Add Arrows -->
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
</swiper>
</div>

<div class="cards-container">
  <div class="cards-container-desktop">
    <div class="cards-container-desktop-card" *ngFor="let new of news"> 
      <app-news-card [new]="new"></app-news-card>
    </div>
    <!-- <div class="cards-container-desktop-card">
      <app-news-card [new]="news[0]"></app-news-card>
    </div>
    <div class="cards-container-desktop-card">
      <app-news-card [new]="news[1]"></app-news-card>
    </div>
    <div class="cards-container-desktop-card">
      <app-news-card [new]="news[2]"></app-news-card>
    </div> -->
  </div>
</div>
