<app-navmenu></app-navmenu>
<app-hero-dosimetria class=hero-container></app-hero-dosimetria>
<div class="page-section">
    <div class="page-section-wrapper">
        <h2>DOSÍMETRO DE FILM</h2>
        <p>Una película que expuesta a radiación ionizante se ennegrece 
            en una placa con diferentes campos de filtros, la medición se realiza 
            a través de la diferenciación de niveles de radiación más penetrantes.
        </p>
        <div class="page-section-wrapper-img">
            <picture>
                <source srcset="../../../assets/dosimetria/film/web_dosimetria_img_film1.jpg" media="(min-width: 750px)">
                <img srcset="../../../assets/dosimetria/film/mobile_dosimetria_img_film1.jpg" alt="…">
            </picture>
        </div>
        <p>Este tipo de dosímetros son utilizados para radiología convencional en 
            medicina o en otros rubros.</p>
        <div class="page-section-wrapper-img">
            <picture>
                <source srcset="../../../assets/dosimetria/film/web_dosimetria_img_film2.jpg" media="(min-width: 750px)">
                <img srcset="../../../assets/dosimetria/film/mobile_dosimetria_img_film2.jpg" alt="…">
            </picture>
        </div>
        <p>
            También se utilizan dosímetros de film pero combinados con dosímetros 
            TLD en las actividades de tipo industrial en donde se utilizan radiaciones.
        </p>
        <div class="page-section-wrapper-img">
            <picture>
                <source srcset="../../../assets/dosimetria/film/web_dosimetria_img_film3.jpg" media="(min-width: 750px)">
                <img srcset="../../../assets/dosimetria/film/mobile_dosimetria_img_film3.jpg" alt="…">
            </picture>
        </div>
    </div>
    <div class="button">
        <button routerLink="/dosimetria" mat-flat-button class=back-button>
            volver
        </button>
    </div>
</div>
<app-accordion-footer></app-accordion-footer>
