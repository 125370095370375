import { AfterContentInit } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-card-destacado',
  templateUrl: './news-card-destacado.component.html',
  styleUrls: ['./news-card-destacado.component.scss']
})
export class NewsCardDestacadoComponent implements AfterContentInit {
  @Input('highlighted')
  highlighted: any;
  noImage: any;
  noImageNoHTML: string;
  noImageNoHTMLShorten: any;
  onlyImage: any;
  parteConSrc: any;
  parteSinSrc: any;
  noImagePost: boolean = false;
  

  constructor() { }


  ngAfterContentInit(){             
    //this.noImage = this.new.description.replace(/<img[^>]*>/gi,"");
    this.noImage = this.highlighted.description.replace(/<img[^>]*>/gi,"");
    this.noImageNoHTML = this.noImage.replace(/<(?:.|\n)*?>/gm, ' ');
    //this.noImageShorten = this.noImageNoHTML.replace(/^(.{100}[^\s]*).*/, "$1") + "\n";
    this.noImageNoHTMLShorten = this.shorten(this.noImageNoHTML, 230) + " ...";
    if (this.highlighted.description.indexOf('src="') == -1 ) {
      this.noImagePost = true
    }
    this.parteConSrc = this.highlighted.description.substring(this.highlighted.description.indexOf('src="'), this.highlighted.description.indexOf('"', this.highlighted.description.indexOf('src="') + 5) );
    //this.parteConSrc = this.highlighted.description.substr(this.highlighted.description.indexOf('src="'), this.highlighted.description.indexOf('src="') + this.highlighted.description.indexOf('"') );
    this.parteSinSrc = this.parteConSrc.substring(this.parteConSrc.indexOf('"')+1, this.parteConSrc.length);
  }

  shorten(str, maxLen, separator = ' ') {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen));
  }

}
