import { New } from './../models/New';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NewsService {

  API_URI = 'https://api.atrr.org.ar/api'

  constructor(private http: HttpClient) { }

  getNews() {
    return this.http.get(`${this.API_URI}/news`);
  }

  getNew(id: number) {
    return this.http.get(`${this.API_URI}/news/${id}`);
  }

  saveNew(news: New) {
    return this.http.post(`${this.API_URI}/news`, news);
  }

  deleteNew(id: string) {
    return this.http.delete(`${this.API_URI}/news/${id}`);
  }

  updateNew(id: string, updatedNew: New): Observable<New> {
    return this.http.put(`${this.API_URI}/news/${id}`, updatedNew);
  } 
}
