import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cards-nosotros',
  templateUrl: './cards-nosotros.component.html',
  styleUrls: ['./cards-nosotros.component.scss']
})
export class CardsNosotrosComponent implements OnInit {
  @Input('nosotrosCardObject')
  nosotrosCardObject:any;

  constructor() { }

  ngOnInit(): void {
  }

}
