import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-swiper-museo',
  templateUrl: './swiper-museo.component.html',
  styleUrls: ['./swiper-museo.component.scss']
})
export class SwiperMuseoComponent {
  coso1 = {
    imgUrl: "../../../../../assets/nosotros/museo/museo1.jpg",
    name: "coso1",
    year: "Ind. Alemana (año 1955).",
    class: "",
    text: "Tubo de ánodo giratorio Rorix 125/30/50.",
  }

  coso2 = {
    imgUrl: "../../../../../assets/nosotros/museo/museo2.jpg",
    name: "coso2",
    year: "Ind. Argentina. (Año 1952).",
    class: "",
    text: "Lámpara inactínica para cuarto obscuro.",
  }

  coso3 = {
    imgUrl: "../../../../../assets/nosotros/museo/museo3.jpg",
    name: "coso2",
    year: "Ind. Argentina (Año 1948).",
    class: "",
    text: "Cono focalizador para equipos portátiles.",
  }

  coso4 = {
    imgUrl: "../../../../../assets/nosotros/museo/museo4.jpg",
    name: "coso2",
    year: "Ind. Estadounidense (Año 1931).",
    class: "",
    text: "Calota y tubo de equipo General Electric.",
  }

  coso5 = {
    imgUrl: "../../../../../assets/nosotros/museo/museo5.jpg",
    name: "coso2",
    year: "Ind. Alemana (Año 1906).",
    class: "",
    text: "Criptoscopio para observación del paciente con RX, frente al operador.",
  }

  coso6 = {
    imgUrl: "../../../../../assets/nosotros/museo/museo6.jpg",
    name: "coso2",
    year: "Ind. Alemana (Año 1930).",
    class: "",
    text: "Válvula de rectificación Rorix (Phönix).",
  }

  coso7 = {
    imgUrl: "../../../../../assets/nosotros/museo/museo7.jpg",
    name: "coso2",
    year: "Ind. Francesa (Año 1912).",
    class: "-small",
    text: "Calota de madera, soporte de tubo de alta tensión, recubierto internamente con 0,8 mm de plomo.",
  }

  coso8 = {
    imgUrl: "../../../../../assets/nosotros/museo/museo8.jpg",
    name: "coso2",
    year: "Ind. Alemana (Año 1922).",
    class: "-small",
    text: "Cono focalizador de vidrio plomado utilizado para la realización de posiciones radiográficas del oído interno.",
  }

  coso9 = {
    imgUrl: "../../../../../assets/nosotros/museo/museo9.jpg",
    name: "coso2",
    year: "Ind. Alemana (Año 1921).",
    class: "",
    text: "Válvula de rectificación Siemens.",
  }

  coso10 = {
    imgUrl: "../../../../../assets/nosotros/museo/museo10.jpg",
    name: "coso2",
    year: "Ind. Estadounidense (Año 1918).",
    class: "",
    text: "Miliamperímetro Standard WM. Meyer (Chicago).",
  }

  coso11 = {
    imgUrl: "../../../../../assets/nosotros/museo/museo11.jpg",
    name: "coso2",
    year: "Ind. Alemana (Año 1927).",
    class: "",
    text: "Funkenstrecke (Nido de la Chispa).",
  }

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 50,
    //centerInsufficientSlides: true,
    //loop: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    loopedSlides: 0,
    roundLengths : true,
  };
}
