import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-socios-form-dialog',
  templateUrl: './socios-form-dialog.component.html',
  styleUrls: ['./socios-form-dialog.component.scss']
})
export class SociosFormDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
