<header class=hero>
    <div class="hero-content">
        <div class="material-icons-outlined">
            play_circle_outline
        </div>
        <div class="hero-text"> 
            <h1>PRÓXIMAMENTE</h1><h2>capacitate ATRR</h2>
        </div>  
    </div>
</header>
