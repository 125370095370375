import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proceso',
  templateUrl: './proceso.component.html',
  styleUrls: ['./proceso.component.scss']
})
export class ProcesoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
