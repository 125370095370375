<app-navmenu></app-navmenu>
<app-hero-capacitate class="hero-container"></app-hero-capacitate>
<div class="page-section">
    <div class="page-section-wrapper">
        <h2>SEGUÍ CAPACITANDOTE</h2>
        <p>
            En ATRR sabemos que lo más importante para un profesional es 
            capacitarse día a día. Horas de capacitación bajo la tutela 
            de los mejores especialistas sobre dosimetría y afines. 
            Descubrí qué curso hemos preparado para vos.
        </p>
    </div>
</div>
<app-course-card></app-course-card>
<app-course-card></app-course-card>
<app-accordion-footer></app-accordion-footer>
