
<div class="swiper-user-acquired-container">
    <swiper [config]="configAcquired" style="height: 330px; width: 100%;" #myswiper>
    <div class="swiper-wrapper">
      <div class="swiper-slide"><app-user-swiper-acquired-card></app-user-swiper-acquired-card> </div>
      <div class="swiper-slide"><app-user-swiper-acquired-card></app-user-swiper-acquired-card></div>
      <!-- <div class="swiper-slide"><app-user-swiper-acquired-card></app-user-swiper-acquired-card></div> -->
    </div>
    
    <!-- Add Pagination -->
    <!-- <div class="swiper-pagination"></div> -->
    <!-- Add Arrows -->
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </swiper>
  </div>