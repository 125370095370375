<app-navmenu></app-navmenu>
<app-hero-nosotros class="hero-container"></app-hero-nosotros>
<div class="page-section">
    <div class="page-section-wrapper">
        <h2>· NUESTROS PROFESIONALES ·</h2>
        <p>Entre todos los colegas que forman parte de la Asociación de Técnicos Radiólogos de 
            Rosario, 
            formamos una red de profesionales técnicos y licenciados idóneos en la actividad. 
            Si necesitas contratar a alguien para tus departamentos de Radiología recomendamos 
            fervientemente a nuestros socios.</p>
        <div class="page-section-wrapper-img">
            <picture>
                <source srcset="../../../assets/nosotros/nuestros-profesionales/web_nuestrosprofesionales_img_unica.jpg" media="(min-width: 750px)">
                <img srcset="../../../assets/nosotros/nuestros-profesionales/mobile_nuestrosprofesionales_img_unica.jpg" alt="…">
            </picture>
        </div>
    </div>
</div>
<div class="page-section">
    <div class="page-section-wrapper">
        <h2>· ¿QUERÉS TRABAJAR CON NUESTROS PROFESIONALES ·</h2>
        <p>Si estás interesado en recibir el listado de profesionales disponibles, 
            por favor, completá el siguiente formulario y nos comunicaremos a la 
            brevedad.</p>
    </div>
</div>
<div class="page-section">
    <div class="page-section-wrapper">
        <form class="contact" novalidate 
        [formGroup]="profesionalesForm"
        (ngSubmit)="save($event)">
            <div class="contact-form">
                <div class="contact-form-col1">
                    <div class="form-warn" *ngIf="profesionalesForm.get('company_name').errors && profesionalesForm.get('company_name').touched">
                        <small *ngIf="profesionalesForm.get('company_name').hasError('required')">
                            Este campo es obligatorio.
                        </small>
                    </div> 
                    <mat-form-field appearance="outline" class="">
                        <input matInput placeholder="nombre de la empresa" type="text" formControlName="company_name">
                    </mat-form-field>
        
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="provincia" type="text" formControlName="province">
                    </mat-form-field>
        
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="ciudad" type="text" formControlName="city">
                    </mat-form-field>
                </div>
                    
                <div class="contact-form-col2">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="persona de contacto" type="text" formControlName="contact_name">
                    </mat-form-field>
        
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="teléfono" type="text" formControlName="phone_number">
                    </mat-form-field>
        
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="correo electrónico" type="text" formControlName="email">
                    </mat-form-field>
                </div>
                
            </div>
            <button mat-flat-button type="submit" class=contact-form-button>enviar</button>
            <button routerLink="/" mat-flat-button class=contact-form-back>volver</button>
        </form>
    </div>
</div>




<app-accordion-footer></app-accordion-footer>
