<header class=hero>
    <div class="hero-content">
        <div class="material-icons-outlined">
            article
        </div>
        <div class="hero-text"> 
            <h1>NOVEDADES</h1><h2>Mantenete informado</h2>
        </div>  
    </div>
</header>
