import { Component, ElementRef, NgZone } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  selector: 'app-museo',
  templateUrl: './museo.component.html',
  styleUrls: ['./museo.component.scss']
})
export class MuseoComponent {

  expansionDisabled: boolean = false;
  expansionExpanded: boolean = false;
  readonly desktopWidth: number = 750;

  width:number;
  observer;
  constructor(
    private host: ElementRef, 
    private zone: NgZone
  ) {}
  ngOnInit() {
    this.observer = new ResizeObserver(entries => {
      this.zone.run(() => {
        this.width = entries[0].contentRect.width;
        if (this.width >= this.desktopWidth) {
          this.expansionDisabled = true;
          this.expansionExpanded = true;
        }
        else {
          this.expansionDisabled = false;
          this.expansionExpanded = false;
        }
      });
    });
    this.observer.observe(this.host.nativeElement);
  }
  ngOnDestroy() {
    this.observer.unobserve(this.host.nativeElement);
  }
}
