<app-navmenu></app-navmenu>
<app-hero-socios class=hero-container></app-hero-socios>
<div class="page-section page-section-socios">
    <div class="page-section-wrapper">
        <h2>· FORMA PARTE DE ATRR ·</h2>
        <p>Quienes conformamos  la ATRR pertenecemos a la institución del sector 
            más antigua del país, que de manera ininterrumpida funciona desde hace 
            40 años. Cientos de colegas han pasado por ella y nos enorgullece que 
            las nuevas generaciones ya no deban preocuparse por los viejos problemas 
            de los que comenzaron en los inicios de la profesión y se ocupen de los 
            nuevos desafíos que los tiempos actuales nos presentan.</p>
        <div class="page-section-wrapper-img">
            <picture>
            <source srcset="../../assets/socios/web_socios_foto1.jpg" media="(min-width: 750px)">
            <img srcset="../../assets/socios/mobile_socios_foto1.jpg" alt="…">
        </picture>
        </div>   
    </div>
</div>
<mat-accordion>
    <mat-expansion-panel class="expansion-dosimetria">
      <mat-expansion-panel-header class="menu-header">
        <mat-panel-title class="title">
          REQUISITOS PARA ASOCIARTE
        </mat-panel-title>
        <mat-panel-description>
          <!-- {{panelOpenState ? 'open' : 'closed'}} -->
        </mat-panel-description>
      </mat-expansion-panel-header>
        <p class="sub">
            Te abrimos los brazos para que te sumes al desafío de pertenecer. 
            Si vivís en la provincia de Santa Fe, sos Licenciado en Tecnologías 
            Médicas, Licenciado en Producción de Bio-imágenes o Técnico Radiólogo 
            podes ser SOCIO/A ACTIVO/A, con todos los derechos que te otorga el 
            Estatuto. <br><br>
            Quienes fueron socios/as por más de 35 años son nuestros “vitalicios”, 
            les agradecemos por lo mucho luchado y obtenido en la profesión. <br><br>
            Si sos estudiante de las carreras más arriba detalladas podes ser socio  
            “adherente”, con un montón de beneficios que también te otorga el Estatuto 
            de nuestra institución.

        </p>
    </mat-expansion-panel>
</mat-accordion>  
<div class="page-section page-section-socios">
    <div class="page-section-wrapper">
        <h2>· BENEFICIOS DE SER PARTE ·</h2>
        <p>Quienes nos asociamos sumamos más beneficios cada año: laborales, 
            profesionales, académicos, becas, subsidios, sorteos, recreativos, de 
            radioprotección. Y esos son solo algunos. Sé parte!</p>
    </div>
</div>
<app-swiper-socios></app-swiper-socios>
<div class="page-section page-section-link">
  <div class="page-section-wrapper">
      <a routerLink="/nosotros/nuestros-profesionales"><p>Nuestros socios</p>
      <span class="material-icons">
          keyboard_arrow_right_round
      </span>
      </a>
  </div>  
</div>
<div class="standard-shadow"></div>
<div class="page-section page-section-contact">
  <div class="page-section-wrapper">
      <section class="contact">
          <div class="contact-description">
              <h2>· DESPEJÁ TUS DUDAS ·</h2>
              <p>Comunicate con nosotros por cualquier medio de contacto. Siempre tendrás respuesta.</p>
          </div>
          <form class="contact-form" novalidate 
          [formGroup]="sociosForm">
              <div class="contact-form-col1">
                <div class="form-warn" *ngIf="sociosForm.get('name').errors && sociosForm.get('name').touched">
                    <small *ngIf="sociosForm.get('name').hasError('required')">
                        Este campo es obligatorio.
                    </small>
                </div> 
                  <mat-form-field appearance="outline" class="">
                      <input matInput placeholder="nombre" type="text" formControlName="name">
                  </mat-form-field>
      
                  <mat-form-field appearance="outline">
                      <input matInput placeholder="apellido" type="text" formControlName="surname">
                  </mat-form-field>
                </div>
                  
              <div class="contact-form-col2">
                  <mat-form-field appearance="outline">
                      <input matInput placeholder="correo electrónico" type="text" formControlName="email">
                  </mat-form-field>
      
                  <mat-form-field appearance="outline">
                      <input matInput placeholder="teléfono" type="text" formControlName="phone_number">
                  </mat-form-field>
      
              </div>
            </form>
          <button mat-flat-button (click)="save($event)" class=contact-form-button>enviar</button>
          <button routerLink="/" mat-flat-button class=contact-form-back>volver</button>
      </section>
  </div>
</div>




<app-accordion-footer></app-accordion-footer>