import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tld',
  templateUrl: './tld.component.html',
  styleUrls: ['./tld.component.scss']
})
export class TldComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
