<div class="swiper-swiper"><div class="swiper-socios-container">
    <swiper [config]="config" style="height: 333px; width: 100%;" #myswiper>
    <div class="swiper-wrapper">
      <div class="swiper-slide"><app-card-socios [sociosObject]="charlasObject"></app-card-socios> </div>
      <div class="swiper-slide"><app-card-socios [sociosObject]="becasObject"></app-card-socios> </div>
      <!-- <div class="swiper-slide"><app-card-socios [sociosObject]="sorteosObject"></app-card-socios> </div> -->
      <div class="swiper-slide"><app-card-socios [sociosObject]="descuentosObject"></app-card-socios> </div>
      <!-- <div class="swiper-slide"><app-card-socios [sociosObject]="descDosimetriaObject"></app-card-socios> </div> -->
      <div class="swiper-slide"><app-card-socios [sociosObject]="complejoObject"></app-card-socios> </div>
    </div>
    
    <!-- Add Pagination -->
    <div class="swiper-pagination"></div>
    <!-- Add Arrows -->
    <!-- <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
  </swiper>
  </div></div>

  <div class="page-section">
    <div class="page-section-wrapper cards-container">
      <div class="cards-container-desktop">
          <div class="cards-container-desktop-card">
            <app-card-socios [sociosObject]="charlasObject"></app-card-socios> 
          </div>
          <div class="cards-container-desktop-card">
            <app-card-socios [sociosObject]="becasObject"></app-card-socios> 
          </div>
        </div>
        <div class="cards-container-desktop">
          <div class="cards-container-desktop-card">
            <app-card-socios [sociosObject]="descuentosObject"></app-card-socios> 
          </div>
          <div class="cards-container-desktop-card">
            <app-card-socios [sociosObject]="complejoObject"></app-card-socios> 
          </div>
        </div>
        <div class="cards-container-desktop">
          <!-- <div class="cards-container-desktop-card">
            <app-card-socios [sociosObject]="sorteosObject"></app-card-socios> 
          </div> -->
          <!-- <div class="cards-container-desktop-card">
            <app-card-socios [sociosObject]="descDosimetriaObject"></app-card-socios> 
          </div> -->
        </div>
    </div>
  </div>
  <!-- <div class="cards-container">
    
  </div> -->
