<app-navmenu></app-navmenu>
<app-hero-contacto class="hero-container"></app-hero-contacto>

        <section class=contact>
            <div class="contact-description">
                <h2>· <span class="contact-description-desktop"> ESCRIBINOS Y </span> DESPEJÁ TUS DUDAS ·</h2>
                <p>Contactate con nosotros, dejanos tu consulta y 
                te responderemos a la brevedad.</p>
            </div>
            <form novalidate 
            [formGroup]="contactForm" class="contact-form">
                <div class="contact-form-inputs">
                    <div class="form-warn" *ngIf="contactForm.get('name').errors && contactForm.get('name').touched">
                        <small *ngIf="contactForm.get('name').hasError('required')">
                            Este campo es obligatorio.
                        </small>
                    </div> 
                    <mat-form-field appearance="outline" class="">
                        <input matInput placeholder="nombre" type="text" formControlName="name">
                    </mat-form-field>
        
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="apellido" type="text" formControlName="surname">
                    </mat-form-field>
        
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="email" type="text" formControlName="email">
                    </mat-form-field>
        
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="teléfono" type="text" formControlName="phone_number">
                    </mat-form-field>  
                </div>
                    
                <div class="contact-form-textarea">
                    <div class="form-warn" *ngIf="contactForm.get('comment').errors && contactForm.get('comment').touched">
                        <small *ngIf="contactForm.get('comment').hasError('required')">
                            Este campo es obligatorio.
                        </small>
                    </div> 
                    <mat-form-field appearance="outline">
                        <textarea matInput placeholder="consulta" type="text" formControlName="comment">

                        </textarea>
                    </mat-form-field>
                </div>
                
            </form>
            <div class="contact-form-buttons-wrapper">
                <div class="contact-form-buttons">
                    <button mat-flat-button (click)="save($event)" class=contact-form-buttons-button>enviar</button>
                    <button routerLink="/"  mat-flat-button class=contact-form-buttons-back>volver</button>
                </div>  
            </div>
        </section>


<div class="address-shadow"></div>
<section class="address">

    <div class="address-address">
        <h3>NUESTRA ASOCIACIÓN</h3>
        <p><span class="material-icons-outlined">location_on</span> Italia 47, Santa Fe, Arg.</p>
        <p><span class="material-icons">stay_primary_portrait</span> +54 341 4261377</p>
        <p><span class="material-icons">mail_outline</span> asociacion@atrr.org.ar</p>
    </div>
    <div class="address-map-container">
        <div class="address-map-container-map">
            <img src="../../assets/contacto/mapa.jpg" alt="">
        </div>
    </div>
</section>  
<app-accordion-footer></app-accordion-footer>
