<app-navmenu></app-navmenu>
<app-hero-login class="hero-container"></app-hero-login>

<div class="page-section">
    <div class="page-section-wrapper">
        <h2>REGISTRATE EN ATRR</h2>
        <p>Cargá tus datos y envialos. Pronto podrás disfrutar de los beneficios de estar registrado.</p>
        <div class="register-form">
            <form novalidate 
            [formGroup]="registerForm" class="register-form-wrapper">
                <div class="obligatorio">*Campos obligatorios</div>
                <div class="register-form-wrapper-col1">
                    <mat-form-field appearance="outline" class="">
                        <input matInput type="text" placeholder="Nombre*" formControlName="name">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="Apellido*" formControlName="surname">
                    </mat-form-field>
                    <div class="conf" *ngIf="usernameConf">
                        Los E-mails no coinciden. Vuelve a intentarlo.
                    </div>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="E-mail*" formControlName="username">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="Confirmación de E-mail*" formControlName="usernameconf">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="Teléfono" formControlName="phone_number">
                    </mat-form-field>
                </div>
                <div class="register-form-wrapper-col2">
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="País" formControlName="country">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="Provincia" formControlName="province">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" placeholder="Ciudad" formControlName="city">
                    </mat-form-field>
                    <div class="conf" *ngIf="passwordConf">
                        Las contraseñas no coinciden. Vuelve a intentarlo.
                    </div>
                    <mat-form-field appearance="outline">
                        <input matInput type="password" placeholder="Contraseña*" formControlName="password">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="password" placeholder="Confirma tu contraseña*" formControlName="passwordconf">
                    </mat-form-field>
                </div>
            </form>
        </div>
        <div class="register-form-buttons">
            <button mat-flat-button class=register-form-buttons-ingresar (click)="save($event)">Enviar</button>
        </div>
        <!-- <div class="login-form-help-link"> 
            <a routerLink="/faq">
                <span class="desktop">Necesito ayuda</span> 
                <span class="mobile">?</span>   
            </a> 
        </div> -->
    </div>
</div>

<app-accordion-footer></app-accordion-footer>