import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  API_URI = 'https://api.atrr.org.ar/api'

  constructor(private http : HttpClient) { }

  public isAuthenticated() : Boolean {
    let userData = localStorage.getItem('userInfo')
    if(userData && JSON.parse(userData)){
      //console.log(userData)
      return true;
    }
    return false;
  }

  public setUserInfo(user){
    console.log('userInfo', user)
    localStorage.setItem('userInfo', JSON.stringify(user));
    //localStorage.setItem('userInfo', user);
    console.log(localStorage.getItem('userInfo'))
  }

  public registerUser(body) {
    return this.http.post(`${this.API_URI}/auth/signup`, body, {
      observe:'body',
      withCredentials:true,
      headers:new HttpHeaders().append('Content-Type','application/json')
        // credentials: 'include',
        //observe: 'body' 
      })
  }

  public validate(body) {
    return this.http.post(`${this.API_URI}/auth/user-login`, body, {
      observe:'body',
      withCredentials:true,
    headers:new HttpHeaders().append('Content-Type','application/json')
        // credentials: 'include',
        //observe: 'body' 
      })
  }

  public loggedIn() {
    return this.http.get(`${this.API_URI}/auth/loggedin`,
    { withCredentials:true })
    //localStorage.removeItem('userInfo');
    //this.route.navigate(['']);
    //return this.http.get(`${this.API_URI}/auth/loggedin`, { withCredentials: true });
    //console.log('pinchila');
  }
  public logOut() {
    localStorage.removeItem('userInfo');
    return this.http.get(`${this.API_URI}/auth/logout`, { withCredentials: true });
  }
}
